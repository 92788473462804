<template>
    <div id="ranking">
        <search-modal :resultSearch="resultSearch" @search="handleSearch" class="" ref="searchUser"></search-modal>


        <div class="direction">
            <div class="name-page">
                <img class="icon-home" src="/themes/web/assets/images/icon/icon-home.svg" alt="">
                <img class="icon-arow" src="/themes/web/assets/images/icon/icon-arrow-right.svg" alt="">
                <p class="title">Ranking</p>
            </div>
            <p class="name">Leaderboards</p>
        </div>
        <div v-if="false" class="view">
            <div class="loading-leaderboard" v-if="isLoading">
                <img class="spinner" src="/themes/web/assets/images/icon/loadding.png" alt="" />
            </div>
            <div class="top">
                <div class="left">
                    <div class="icon-search"  @click="searchUser()">
                        <img src="/themes/web/assets/images/icon/icon-search-1.svg" alt="">
                    </div>
                    <!-- <div class="btn-text btn-friends">Friends</div> -->
                    <!-- <div class="btn-text active btn-school">School</div> -->
                    <div class="btn-text active btn-all-users">All Users</div>
                </div>
                <div class="right">
                    <button @click="prev()" class="igs-btn purple"><img src="/themes/web/assets/images/icon/arrow-left.svg" alt=""></button>
                    <div class="info-date">
                        <p>{{ month_txt }} {{ year }}</p>
                    </div>
                    <button @click="next()" :class="lastNext ? 'igs-btn disable' : 'igs-btn'"><img src="/themes/web/assets/images/icon/arrow-right.svg" alt=""></button>
                </div>
            </div>
            <div class="line"></div>
            <div class="content">
                 <!-- TOP-GEM -->
                <div class="top-gem">
                    <div class="header">
                        <!-- <h4>Top Gem</h4>
                        <div class="name-school">Name of School</div> -->
                       
                        <div v-if="search" class="item-search">
                            <p>@{{ searchValue }}</p>
                            <img @click="removeSearch()" src="/themes/web/assets/images/icon/icon-closed.svg" alt="">
                        </div>
                        <h4 v-else>Top Gem</h4>

                    </div>
                    <div class="leaderboard">
                        <div v-if="my_rank_gem.length == 0" class="note-rate">
                            <div class="">
                                <p>YOU ARE NOT RATED.</p>
                                <p v-if="month_now == month && year_now == year">Study now to collect gem.</p>
                            </div>
                            <button v-if="month_now == month && year_now == year" @click="goHome()" class="igs-btn">Go</button>
                        </div>
                        <!-- -------------------- -->
                        <div v-for="(ldb, index) in gem_leaderboards" :key="ldb.user_id" >
                            <div :class="{'item-user current-user' :  ldb.user_id == user_info.user_id && !search, 'item-user search-user' : ldb.user_id != user_info.user_id && search,'item-user' : true,}">
                                <img v-if="ldb.rank == 1" src="/themes/web/assets/images/icon/icon-top1.svg" alt="">
                                <img v-else-if="ldb.rank == 2" src="/themes/web/assets/images/icon/icon-top2.svg" alt="">
                                <img v-else-if="ldb.rank == 3" src="/themes/web/assets/images/icon/icon-top3.svg" alt="">
                                <div v-else-if="ldb.rank > 3" class="number">{{ ldb.rank }}</div>
                                <img style="width: 48px; height: 48px;" :src="ldb.avatar_url!= '' ? ldb.avatar_url : '/themes/web/assets/images/icon/avatar-default.svg'">
                                <div class="info-user">
                                    {{ldb.user_id == user_info.user_id ? "YOU" : ldb.fullname}}
                                </div>
                                <div class="result">
                                    <span>{{ ldb.gem | toCommas  }}</span>
                                    <img src="/themes/web/assets/images/icon/icon-diamond-small.svg" alt="">
                                </div>
                            </div>
                        </div>
                    
                        <div v-if="!search && total_gem_leaderboards.length > page_gem" @click="addTopGem()" class="btn-more">Click here to see more users</div>

                        <div v-if="!hide_bottom_gem && !search">
                            <div v-for="(ldb, index) in bottom_gem_leaderboards" :key="ldb.user_id" >
                                <div :class="{'item-user current-user' :  ldb.user_id == user_info.user_id && !search, 'item-user search-user' : ldb.user_id != user_info.user_id && search,'item-user' : true,}">
                                    <img v-if="ldb.rank == 1" src="/themes/web/assets/images/icon/icon-top1.svg" alt="">
                                    <img v-else-if="ldb.rank == 2" src="/themes/web/assets/images/icon/icon-top2.svg" alt="">
                                    <img v-else-if="ldb.rank == 3" src="/themes/web/assets/images/icon/icon-top3.svg" alt="">
                                    <div v-else-if="ldb.rank > 3" class="number">{{ ldb.rank }}</div>
                                    <img style="width: 48px; height: 48px;" :src="ldb.avatar_url!= '' ? ldb.avatar_url : '/themes/web/assets/images/icon/avatar-default.svg'">
                                    <div class="info-user">
                                        {{ldb.user_id == user_info.user_id ? "YOU" : ldb.fullname}}
                                    </div>
                                    <div class="result">
                                        <span>{{ ldb.gem | toCommas  }}</span>
                                        <img src="/themes/web/assets/images/icon/icon-diamond-small.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ----------------- -->
                        <div v-if="my_rank_gem.length > 0 && total_gem_leaderboards.length != 1 && !search" class="bottom">
                            <p class="you" v-if="my_rank_gem[0].rank == total_gem_leaderboards.length">YOU’RE AT THE LAST POSITION</p>
                            <p v-else>You have more gem than {{  total_gem_leaderboards.length - my_rank_gem[0].rank }} users.</p>
                        </div>
                    </div>
                </div>


                <!-- TOP-CROWN -->
                <div class="top-crown">
                    <div class="header">
                        <div v-if="search" class="item-search">
                            <p>@{{ searchValue }}</p>
                            <img @click="removeSearch()" src="/themes/web/assets/images/icon/icon-closed.svg" alt="">
                        </div>
                        <h4 v-else>Top Crown</h4>
                    </div>
                    <div class="leaderboard">
                        <div v-if="my_rank_crown.length == 0" class="note-rate">
                            <div class="">
                                <p>YOU ARE NOT RATED.</p>
                                <p v-if="month_now == month && year_now == year">Study now to collect crowns.</p>
                            </div>
                            <button v-if="month_now == month && year_now == year" @click="goHome()" class="igs-btn">Go</button>
                        </div>

                        <!-- ---------------- -->
                        <div v-for="(ldb, index) in crown_leaderboards" :key="ldb.user_id" >
                            <div :class="{'item-user current-user' :  ldb.user_id == user_info.user_id && !search, 'item-user search-user' : ldb.user_id != user_info.user_id && search,'item-user' : true,}">
                                <img v-if="ldb.rank == 1" src="/themes/web/assets/images/icon/icon-top1.svg" alt="">
                                <img v-else-if="ldb.rank == 2" src="/themes/web/assets/images/icon/icon-top2.svg" alt="">
                                <img v-else-if="ldb.rank == 3" src="/themes/web/assets/images/icon/icon-top3.svg" alt="">
                                <div v-else-if="ldb.rank > 3" class="number">{{ ldb.rank }}</div>
                                <img style="width: 48px; height: 48px;" :src="ldb.avatar_url!= '' ? ldb.avatar_url : '/themes/web/assets/images/icon/avatar-default.svg'">
                                <div class="info-user">
                                    {{ldb.user_id == user_info.user_id ? "YOU" : ldb.fullname}}
                                </div>
                                <div class="result">
                                    <span>{{ ldb.gem | toCommas  }}</span>
                                    <img src="/themes/web/assets/images/icon/icon-crown.svg" alt="">
                                </div>
                            </div>
                        </div>

                        <div v-if="!search && total_crown_leaderboards.length > page_crown" @click="addTopCrown()" class="btn-more">Click here to see more users</div>

                        <div v-if="!hide_bottom_crown && !search">
                            <div v-for="(ldb, index) in bottom_crown_leaderboards" :key="ldb.user_id" >
                                <div :class="{'item-user current-user' :  ldb.user_id == user_info.user_id && !search, 'item-user search-user' : ldb.user_id != user_info.user_id && search,'item-user' : true,}">
                                    <img v-if="ldb.rank == 1" src="/themes/web/assets/images/icon/icon-top1.svg" alt="">
                                    <img v-else-if="ldb.rank == 2" src="/themes/web/assets/images/icon/icon-top2.svg" alt="">
                                    <img v-else-if="ldb.rank == 3" src="/themes/web/assets/images/icon/icon-top3.svg" alt="">
                                    <div v-else-if="ldb.rank > 3" class="number">{{ ldb.rank }}</div>
                                    <img style="width: 48px; height: 48px;" :src="ldb.avatar_url!= '' ? ldb.avatar_url : '/themes/web/assets/images/icon/avatar-default.svg'">
                                    <div class="info-user">
                                        {{ldb.user_id == user_info.user_id ? "YOU" : ldb.fullname}}
                                    </div>
                                    <div class="result">
                                        <span>{{ ldb.gem | toCommas  }}</span>
                                        <img src="/themes/web/assets/images/icon/icon-crown.svg" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ----------------- -->
                        <div v-if="my_rank_crown.length > 0 && total_crown_leaderboards.length != 1 && !search" class="bottom">
                            <p class="you" v-if="my_rank_crown.rank == total_crown_leaderboards.length">YOU’RE AT THE LAST POSITION</p>
                            <p v-else>You have more crowns than {{ total_crown_leaderboards.length - my_rank_crown[0].rank }} users.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <img src="/themes/web/assets/images/icon/banner-comming-soon.png" alt="">
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import searchModal from "../modal/home/searchModal.vue";
    import { getClasses } from "../../models/classes";
    import { getLeaderboard } from "../../models/leadboard";




    export default {
        name: "Ranking",
        title: 'Ranking',

        components:{searchModal},

        data() {
            const d = new Date();
            const month = d.getMonth();
            const year = d.getFullYear();

            const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];

            return{
                isLoading: true,
                lastNext: true,
                months: months,
                year: year,
                month: month,
                monthCopy: month + 1,
                month_txt: months[month],
                total_gem_leaderboards: [],
                gem_leaderboards: [],
                total_crown_leaderboards: [],
                crown_leaderboards: [],
                bottom_crown_leaderboards: [],
                bottom_gem_leaderboards: [],
                list_search_gem: [],
                list_search_crown: [],
                classes: [],
                my_rank: null,
                page_gem: 3,
                page_crown: 3,
                my_rank_gem: [],
                my_rank_crown: [],
                hide_bottom_crown: false,
                hide_bottom_gem: false,
                search: false,
                searchValue: "",
                resultSearch: true,
                year_now: year,
                month_now: month,
            }
        },

        computed: {
            user_info() {
                return this.$store.state.user_info;
            },
        },

        watch: {
            
        },

        created() {
            this.getLeaderboard(this.year, this.month + 1);
            this.$store.commit('setMenuActive', 'ranking')
        },

        methods: {
            handleSearch(searchedValue){
                this.searchValue = searchedValue
                this.crown_leaderboards = this.total_crown_leaderboards.filter((element, index) => (element.username.toLowerCase() == searchedValue.toLowerCase() || element.user_id == this.user_info.user_id));
                this.gem_leaderboards = this.total_gem_leaderboards.filter((element, index) => (element.username.toLowerCase() == searchedValue.toLowerCase() || element.user_id == this.user_info.user_id));

                if(this.crown_leaderboards.length > 1 || this.gem_leaderboards.length > 1 ){
                    this.resultSearch = true
                }else{
                    this.resultSearch = false
                }

                this.search = true
            },
            removeSearch(){
                this.searchValue = ""
                this.gem_leaderboards =  this.total_gem_leaderboards.filter((element, index) => index < this.page_gem);
                this.crown_leaderboards =  this.total_crown_leaderboards.filter((element, index) => index < this.page_crown);
                this.search = false
            },
            goHome(){
                this.$router.push("/")
            },
            prev(){
                const that = this;
                const year_prev = (that.monthCopy) == 1 ? parseInt(that.year) - 1 : that.year;
                const month_prev = (that.monthCopy) == 1 ? 12 : parseInt(that.monthCopy) - 1;
                that.$store.commit("setLoading", true);
                that.lastNext = false
                this.getLeaderboard(year_prev, month_prev)


                this.searchValue = ""
                this.gem_leaderboards =  this.total_gem_leaderboards.filter((element, index) => index < this.page_gem);
                this.crown_leaderboards =  this.total_crown_leaderboards.filter((element, index) => index < this.page_crown);
                this.search = false
            },
            next(){
                const that = this;
                const date = new Date();
                const current_month = date.getMonth() + 1;
                const current_year = date.getFullYear();
                const year_next = that.monthCopy == 12 ? parseInt(that.year) + 1 : that.year;
                const month_next = that.monthCopy == 12 ? 1 : parseInt(that.monthCopy) + 1;
                if (current_year == year_next && current_month < month_next) {
                    return;
                }
                if (current_year == year_next && current_month == month_next) {
                    that.lastNext = true
                }
                this.getLeaderboard(year_next, month_next)


                this.searchValue = ""
                this.gem_leaderboards =  this.total_gem_leaderboards.filter((element, index) => index < this.page_gem);
                this.crown_leaderboards =  this.total_crown_leaderboards.filter((element, index) => index < this.page_crown);
                this.search = false
            },
            getLeaderboard(year, month) {
                const that = this;
                that.isLoading = true;

                axios.all([
                    getLeaderboard({
                        year,
                        month: month
                    }),
                ]).then(axios.spread((response) => {
                    if (response.data.code != 0) {
                        return;
                    }

                    that.total_gem_leaderboards = response.data.results.gem.leaderboard || [];
                    that.gem_leaderboards =  that.total_gem_leaderboards.filter((element, index) => index < this.page_gem);
                    if( that.total_gem_leaderboards.length > 3){
                        const indexYou = that.total_gem_leaderboards.findIndex(item => item.user_id === this.user_info.user_id);
                        that.bottom_gem_leaderboards  = (indexYou !== -1 && indexYou > 3) ? [that.total_gem_leaderboards[indexYou], that.total_gem_leaderboards[indexYou + 1] || that.total_gem_leaderboards[indexYou - 1]] : that.total_gem_leaderboards.slice(-2);
                    }else{
                        that.bottom_gem_leaderboards = []
                    }


                    that.total_crown_leaderboards = response.data.results.crown.leaderboard || [];
                    that.crown_leaderboards =  that.total_crown_leaderboards.filter((element, index) => index < this.page_crown);
                    if( that.total_crown_leaderboards.length > 3){
                        var indexYou1 = that.total_crown_leaderboards.findIndex(item => item.user_id === this.user_info.user_id);
                        that.bottom_crown_leaderboards  = (indexYou1 !== -1 && indexYou1 > 3) ? [that.total_crown_leaderboards[indexYou1], that.total_crown_leaderboards[indexYou1 + 1] || that.total_crown_leaderboards[indexYou1 - 1]] : that.total_crown_leaderboards.slice(-2);
                    }else{
                        that.bottom_crown_leaderboards = []
                    }


                    this.my_rank_gem =  this.total_gem_leaderboards.filter((element, index) => element.user_id == this.user_info.user_id);
                    this.my_rank_crown =  this.total_crown_leaderboards.filter((element, index) => element.user_id == this.user_info.user_id);


                    that.year = year;
                    that.month = month - 1;
                    that.monthCopy = month;
                    that.month_txt = that.months[that.monthCopy - 1];
                    this.isLoading = false
                    this.$nextTick(function() {
                        initNanoScroller();
                    })                    
                }));
            },

            addTopGem(){
                this.page_gem+=10
                this.gem_leaderboards =  this.total_gem_leaderboards.filter((element, index) => index < this.page_gem);
                this.hide_bottom_gem = true
            },

            addTopCrown(){
                this.page_crown+=10
                this.crown_leaderboards =  this.total_crown_leaderboards.filter((element, index) => index < this.page_crown);
                this.hide_bottom_crown = true
            },

            getClasses(){
                axios.all([
                    getClasses(),
                ]).then(axios.spread((response) => {

                    if (response.data.code != 0) {
                        return;
                    }
                    
                    this.classes = response.data.results

                    this.$nextTick(function() {
                        initNanoScroller();
                    })                    
                }));
            },

            async searchUser() {
                const cfm = await this.$refs.searchUser.show({
                    lang: this.lang,
                });
                if (!cfm) {
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .spinner{
        margin-top: 30%;
        margin-left: calc(50% - 25px);
    }
    .loading-leaderboard{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    #ranking{
        padding-bottom: 20px;
    }
    .view{
        position: relative;
        background: #FFFFFF;
        width: 100%;
        /* height: 718px; */
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        padding: 28px 34px 24px 48px;
    }
    .content{
        display: flex;
        justify-content: space-between;
        gap: 16px;
    }
    .line{
        width: 100%;
        border: 1px solid #CFCFCF;
        margin-top: 8px;
        margin-bottom: 20px;
    }

    .top{
        height: 56px;
        display: flex;
        justify-content: space-between;
    }
    .icon-search{
        width: 56px;
        height: 56px;
        border-width: 1px 1px 6px 1px;
        border-style: solid;
        border-color: #DDDDDD;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .left{
        display: flex;
        gap: 12px;
    }
    .right{
        display: flex;
        align-items: center;
    }
    .right .igs-btn{
        width: 68px;
        height: 48px;
    }
    .igs-btn.disable{
        border: 1px solid var(--Cloud-Dark, #CFCFCF);
        background: var(--Cloud-Light, #DDD);
        box-shadow: 4px 4px 0px 0px #CFCFCF;
    }
    .right p:nth-child(1){
        color: #A560EB;
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 20px;
    }
    .right p:nth-child(2){
        font-weight: 600;
        font-size: 16px;
        color: #777777;
        letter-spacing: 0.02em;
    }
    .right .info-date{
        margin: 0 24px;
        text-align: center;
    }
    .top-gem{
        border: 1px solid #CFCFCF;
        border-radius: 24px;
        /* height: 582px; */
        width: 491px;
        padding: 22.5 16px 16px 24px;
    }
    .top-crown{
        border: 1px solid #CFCFCF;
        border-radius: 24px;
        /* height: 582px; */
        width: 499px;
        padding: 22.5 16px 16px 24px;
    }
    .content .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* height: 72px; */
        border-bottom: 1px solid #CFCFCF;
        padding-bottom: 20px;
    }
    .content .header h4{
        font-weight: 700;
        font-size: 24px;
        color: #4C4C4C;
        letter-spacing: 0.02em;
    }
    .content .header .name-school{
        font-weight: 700;
        font-size: 16px;
        color: #A560EB;
        letter-spacing: 0.02em;
        height: 51px;
        padding: 0 24px;
        border: 1px solid #CFCFCF;
        border-radius: 16px;
        display: flex;
        align-items: center;
    }
    .leaderboard{
        margin-top: 16px;
        width: 100%;
        max-height: 462px;
        overflow-y: auto;
    }
    .leaderboard .item-user{
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 20px;
        gap: 24px;
        border-radius: 24px;
    }
    .leaderboard .bottom{
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #CFCFCF;
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 16px;
    }
    .leaderboard .note-rate{
        height: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFE9DA;
        border-radius: 24px;
        padding-left: 24px;
        padding-right: 20px;
    }

    .leaderboard .note-rate p:nth-child(1){
        font-weight: 700;
        font-size: 16px;
        color: #FC7A1B;
        letter-spacing: 0.02em;
        margin-bottom: 4px;
    }
    .leaderboard .note-rate p:nth-child(2){
        font-weight: 400;
        font-size: 16px;
        color: #FC7A1B;
        letter-spacing: 0.02em;
    }
    .leaderboard .note-rate .igs-btn{
        background: #FFFFFF;
    }
    .leaderboard .bottom .you{
        color: #FF3D54;
    }
    .leaderboard .item-user.current-user{
        background: #EAD4FF;
    }
    .leaderboard .item-user.search-user{
        /* background: var(--Pumpkin-Very-Light, #FFE9DA); */
        /* background: var(--Very-Light-Blueberry, #DBEAFE); */
    }
    .leaderboard .item-user.search-user{
        background: var(--Very-Light-Blueberry, #DBEAFE);
    }
    .leaderboard .item-user.search-user .info-user, .leaderboard .item-user.search-user .result span{
        /* color: #FC7A1B !important; */
        color: var(--Blueberry-Light, #02A6E7);
    }
    .leaderboard .item-user.current-user .result span{
        color: #A560EB !important;
    }
    .leaderboard .item-user.current-user .number{
        color: #A560EB;
    }
    .leaderboard .item-user.search-user .number{
        /* color: var(--Pumpkin-Light, #FC7A1B); */
        color: var(--Blueberry-Light, #02A6E7);
    }
    .leaderboard .item-user .result{
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .leaderboard .item-user span{
        color: #777777;
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 16px;
    }
    .leaderboard .item-user .info-user{
        width: 160px;
        font-weight: 700;
        font-size: 16px;
        color: #191715;
        display: flex;
        align-items: center;
    }
    .leaderboard .item-user .number{
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #000000;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn-more{
        color: #A560EB;
        letter-spacing: 0.02em;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        margin: 8px 0;
        cursor: pointer;
    }
    .item-search{
        color: #A560EB;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.02em;
        border: 1px solid #CFCFCF;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 51px;
        gap: 24px;
        filter: drop-shadow(4px 4px 0px #DDDDDD);
        background: #FFFFFF;
    }
    .item-search img{
        cursor: pointer;
    }
    .search-modal{

    }
    .btn-all-users{
        background: var(--ead-5-ff, #EAD4FF);
    }
</style>
