import {getPreview, postPreview} from "../APIGateWay";
// -------------IGS-------------
export async function getAllCourse(params = {}) {
    return await getPreview(params, `/previewcourses`)
}

export async function getModules(params = {}) {
    return await getPreview(params, `/previewcourses/modules`)
}

export async function getModule(params = {}) {
    return await getPreview(params, `/previewcourses/module`)
}

export async function getUnit1(params = {}) {
    return await getPreview(params, `/previewcourses/get-module-1`)
}

export async function getLesson(params = {}) {
    return await getPreview(params, `/previewcourses/get-lesson`)
}

export async function getProgram(params = {}) {
    return await getPreview(params, `/previewcourses/programs`)
}


export async function getListItem(params = {}) {
    return await getPreview(params, '/previewcourses/items')
}

export async function saveCurrentItem(params) {
    return await postPreview(params, '/previewcourses/save-current-item')
}

export async function updateItem(params = {}) {
    return await postPreview(params, '/previewcourses/updateitem')
}

export async function searchLearning(params = {}) {
    return await getPreview(params, `/previewcourses/search`)
}


export async function submitItem(params = {}) {
    return await postPreview(params, `/previewcourses/submititem`)
}

export async function getLessonPackage(params = {}) {
    return await getPreview(params, '/previewcourses/lessonpackages')
}