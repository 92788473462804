<template>
    <popup-modal ref="popup">
        <div class="profile-modal">
            <a href="javascript:void(0)" class="close-modal">
                <img @click="closeModal" :src="closeImg" alt="Close">
            </a>
            <div style="padding-right: 48px;">
                <profile @showTranscript="handleTranscript"></profile>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal.vue'
import Profile from '../../profile/Profile.vue'


export default {
    name: "ConfirmSubmit",
    components: { PopupModal,  Profile},
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-1.png',
        title: undefined,
        message: undefined, // Main text content
        search: true,
    }),
    mounted(){

    },
    created(){
       
    },
    methods: {
        handleTranscript(a){
            this.$emit('showTranscript', a);
        },
        show(opts = {}) {
            this.title = "Search User"
            this.$refs.popup.open()
        },
        closeModal() {
            this.$refs.popup.close();
        }
    },
}
</script>

<style scoped>
    .profile-modal{
        width: 1152px;
        /* height: 624px; */
        padding: 48px;
        padding-top: 0;
        padding-right: 0;
        /* position: relative; */
    }
    .title{
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
    }
    
    .close-modal{
        /* position: absolute; */
        display: flex;
        justify-self: flex-end;
        justify-content: flex-end;
        /* width: 424px; */
        right: 0;
        cursor: default;
        top: 0;
    }
    .close-modal img{
        cursor: pointer;
    }
    .icon-search{
        background: #A560EB;
        border: 1px solid #8549BA;
        box-shadow: 4px 4px 0px #854ABA;
        border-radius: 16px;
        cursor: pointer;
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .icon-search:active{
        position: relative;
        box-shadow: none;
        top: 4px;
        left: 4px;
    }
    .content{
        display: flex;
        justify-content: space-between;
        gap:8px;
        align-items: center;
        margin-top: 16px;
    }
    .content input{
        height: 60px;
        width: 264px;
        background: #F8FAFC;
        border: 1px solid #DDDDDD;
        border-radius: 16px;
        padding: 16px 24px;
        outline: none;
    }
    .error{
        color: #FF3D54;
        align-items: center;
        letter-spacing: 0.02em;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        margin-top: 16px;
    }

    .info-modal{
        height: 520px;
        width: 477px;
        padding: 24px 48px;
    }
    .profile img{
        width: 232px;
        height: 232px;
        text-align: center;
        margin-top: 16px;
    }
    .profile{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profile p:nth-child(2){
        font-weight: 700;
        font-size: 20px;
        color: #191715;
        letter-spacing: 0.02em;
        margin-top: 16px;
    }
    .profile p:nth-child(3){
        font-weight: 500;
        font-size: 18px;
        color: #777777;
        letter-spacing: 0.02em;
        margin-top: 10px;
    }
    .btn-add-friend{
        width: 100%;
        height: 52px;
        text-align: center;
        color: #A560EB;
        font-weight: 700;
        font-size: 16px;
        border: 1px solid #CFCFCF;
        box-shadow: 4px 4px 0px #CFCFCF;
        border-radius: 48px;
        cursor: pointer;
        margin-top: 32px;
        background: #FFFFFF;
    }
    .btn-add-friend:active{
        position: relative;
        box-shadow: none;
        top: 4px;
        left: 4px;
    }
    .btn-add-friend.remove{
        color: #FF3D54;
    }
</style>