import axios from './request'
import axiosPreview from './request_preview'
import axiosOrigin from 'axios'
import {store} from './store'

function getHeaders() {
    const headers = {
        'Content-Type': 'application/json'
    };

    const isCmsPreview = store.getters.isCmsPreview;

    if (!isCmsPreview) {
        headers['Authorization'] = 'Bearer ' + getCookie('_wid');

        if (getCookie('_permtoken')) {
            headers['Perm-Token'] = getCookie('_permtoken');
        }
    } else {
        headers['Perm-Token'] = '';
        headers['Cms-Token'] = getCookie('_cmstoken');
    }

    return headers;
}

export async function fetchWithHeaders(url, params = {}, options = {}) {
   const headers = getHeaders();

   const defaultOptions = {
        headers,
        keepalive: true,
        method: 'POST',
        body: JSON.stringify(params) 
    };

    const finalOptions = { ...defaultOptions, ...options };

    try {
        const response = await fetch(process.env.VUE_APP_API_URL  + url, finalOptions);
        if (!response.ok) {
            const errorData = await response.json();
        }
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }

    // const urlParams = new URLSearchParams(params).toString();
    // const fullUrl = `${url}?${urlParams}`;
    // const defaultOptions = {
    //     headers,
    //     keepalive: true
    // };
    // const finalOptions = { ...defaultOptions, ...options };

    // const response = await fetch(fullUrl, finalOptions);

    // if (!response.ok) {
    //     throw new Error('Network response was not ok');
    // }

    // const data = await response.json();
    // return data;
}

export async function get(params, url) {
    const res = await axios.get(process.env.VUE_APP_API_URL + url, 
        {  
            params
        } 
    )
    return res
}

export async function get_1(url, params = {}) {
    const headers = {
        "Accept" : "*",
        "Content-Type" : "application/pdf",
        'X-school-uuid': 'igs'
    };

    console.log(process.env.VUE_APP_SIS_API + url);

    const res = await axios.get(process.env.VUE_APP_SIS_API + url, {
        headers,
        params
    });
    return res;
}

export async function callApiGet(params = {}) {
    const res = await axios.get(params.url + '/itocommon/get_user_avatar/' + params.id, {
    });
    return res;
}


export async function get_2(url, params = {}) {
    const headers = {
        "Accept" : "*",
        "Content-Type" : "application/pdf",
        'X-school-uuid': 'igs'
    };

    const res = await axios({
        method: "get",
        url: process.env.VUE_APP_SIS_API + url,
        params: params,
        headers,
        responseType: "blob"
    })

    return res;
}

export async function post(params, url) {
    const res = await axios.post(process.env.VUE_APP_API_URL + url,  params);
    return res
}

export async function post_1(params, url) {
    const res = await axios.post(url, params);
    return res
}

export function getCookie(key){
    const cookies = document.cookie
                        .split(';')
                        .map(cookie => cookie.split('='))
                        .reduce((accumulator, [key, value]) => 
                        ({...accumulator, [key.trim()]: decodeURIComponent(value)}),{})
    return cookies[key]
}

export async function getPreview(params, url) {
    const res = await axiosPreview.get(process.env.VUE_APP_API_URL + url, 
        {  
            params
        } 
    )
    return res
}

export async function postPreview(params, url) {
    const res = await axiosPreview.post(process.env.VUE_APP_API_URL + url,  params);
    return res
}

function getFormData(params){
    var bodyFormData = new FormData();
    Object.keys(params).forEach((key) => {
        bodyFormData.append(key, params[key])
    })
    return bodyFormData
}