import {get, post, get_1, get_2, callApiGet} from "../APIGateWay";

export async function updateAvatarParent(data) {
    return await post(data, '/account-parent/update-avata')
}
export async function updateAvatar(data) {
    return await post(data, '/user/update')
}

export async function updateProfile(data) {
    return await post(data, '/user/change-info')
}

export async function updateParentProfile(data) {
    return await post(data, '/parent/change-info')
}

export async function updateContact(data) {
    return await post(data, '/parent/change-contact')
}

export async function updatePassword(data) {
    return await post(data, '/password')
}

export async function getProfile(params = {}) {
    return await get(params, '/user/info')
}

export async function getParentProfile(data) {
    return await post(data, '/account-parent/get-user-info')
}

export async function getAvatars(params={}) {
    return await callApiGet(params)
}

export async function getConfig() {
    return await get({}, '/get-config')
}

export async function updateLanguage(data) {
    return await post(data, '/user/update-language')
}

export async function getParentSettings() {
    return await get({}, '/parent/parents-setting')
}

export async function updateSetingNofification(data) {
    return await post(data, '/notification/change')
}

//SIS CMS
export async function getProgramsByUser(id) {
    return await get_1('/user-service/students/' + id)
}

export async function getAllPrograms() {
    return await get_1('/school-service/programs')
}

export async function viewTranscript(params = {}) {
    return await get_2('/utility-service/export/transcript-academic', params)
}