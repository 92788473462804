<template> 
    <div>
        <div class="daily-challenge-item" v-if="challenge.status==0">
            <div class="daily-challenge-item-body">
                <div class="title">
                    <img src="/themes/web/assets/images/icon/key-active.svg" alt="">
                    <p>{{ challenge.key_open }}</p>
                </div>
                <div style="width: 164px; height: 160px;">
                    <img src="/themes/web/assets/images/icon/chest-open.svg" alt="">
                </div>
            </div>
        </div>     
        
        <div class="daily-challenge-item active" v-if="challenge.status==1 && challenge.key_open<=key_collect">
            <div class="daily-challenge-item-body">
                <div class="title">
                    <img src="/themes/web/assets/images/icon/key-active.svg" alt="">
                    <p>{{ challenge.key_open }}</p>
                </div>
                <div style="width: 164px; height: 160px;">
                    <img src="/themes/web/assets/images/icon/chest-close.svg" alt="">
                </div>
                <div class="btn-open">
                    <button class="igs-btn" @click="claim()">Open</button>
                </div>
            </div>
        </div>      

        <div class="daily-challenge-item" v-if="challenge.status==2">
            <div class="daily-challenge-item-body">
                <div class="title">
                    <img src="/themes/web/assets/images/icon/key-active.svg" alt="">
                    <p>{{ challenge.key_open }}</p>
                </div>
                <div style="width: 164px; height: 160px;">
                    <img src="/themes/web/assets/images/icon/chest-open.svg" alt="">
                </div>
            </div>
        </div>

        <div class="daily-challenge-item block" v-if="(challenge.status==1 && challenge.key_open>key_collect) || challenge.status==4">
            <div class="daily-challenge-item-body">
                <div class="title">
                    <img src="/themes/web/assets/images/icon/key-active.svg" alt="">
                    <p>{{ challenge.key_open }}</p>
                </div>
                <div style="width: 164px; height: 160px;">
                    <img src="/themes/web/assets/images/icon/chest-disable.svg" alt="">
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
    import axios from "axios";
    import {mapGetters} from 'vuex'
    import {claimChestDailyChallenge} from "../../models/reward";
    import {soundClick, soundGetReward, soundClaimKey} from '../../utils/utils';

    export default {
        name: "ChallengeItem",

        props: ['challenge','key_collect','isLoading'],

        computed: {
            ...mapGetters([
                'user_info'
            ], () => {
                if (this.user_info) {
                    return this.user_info
                }

                return {
                    gem: 0
                }
            }),
            loading: {
                get() {
                    return this.isLoading
                },
                set(val) {
                    this.$emit('update:isLoading', val)
                }
            }
        },

        data() {
            return {

            };
        },

        created() {

        },

        methods: {
            claim() {
                const that = this;

                that.getSoundClick();
                that.loading = true;
                claimChestDailyChallenge({
                    id: that.challenge.id,
                    //is_raw: true
                }).then((response) => {
                    if (response.data.code != 0) {
                        return;
                    }

                    const new_gem = response.data.results.update_gem || 0;
                    const new_key_collect = response.data.results.key_collect || 0;
                    const new_challenges = response.data.results.challenge || [];
                    const gem_reward = response.data.results.gem_reward || [];

                    //const gem = new_gem - that.user_info.gem;

                    that.user_info.gem = new_gem;
                    this.$emit('updateNewChallenge', new_challenges, new_key_collect)

                    const gem_txt = gem_reward > 0 ? '+' + gem_reward : gem_reward;
                    that.loading = false;
                    // $('#congratulation_gem').text(gem_txt);

                    // that.getSoundGetReward();
                    that.getSoundClaimKey();
                    
                    // $('#getRewardedModal').modal();
                }).catch(() => {
                    // console.error(response);
                });
            },

            getSoundClick() {
                soundClick();
            },

            getSoundGetReward() {
                soundGetReward();
            },

            getSoundClaimKey() {
                soundClaimKey();
            }
        }
    }
</script>

<style scoped>
    .daily-challenge-item{
        padding: 24px 12px;
        border: 1px solid #DDDDDD;
        border-radius: 24px;
        height: 240px;
        background: #EAD4FF;
        width: 180px;
    }
    .title{
        display: flex;
        gap: 10px;
        justify-content: center;
    }
    .title p{
        font-weight: 900;
        font-size: 28px;
        color: #777777;
    }

    .daily-challenge-item.block{
        background: #F8FAFC;
        mix-blend-mode: luminosity;
    }
    .daily-challenge-item.active{
        background: #A560EB;
        position: relative;
    }

    .daily-challenge-item.active .title p{
        font-weight: 900;
        font-size: 28px;
        color: #FFFFFF;
    }
    .btn-open{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .igs-btn{
        height: 48px;
    }
</style>
