import VueRouter from 'vue-router'
import { Forbidden, NotFound, Welcome } from './components'
import { Login, Register } from './components/auth'
import { Inbox } from './components/inbox'
import { LiveClass } from './components/liveclass'
import { Reward } from './components/reward'
import { Ranking } from './components/ranking'
import { Subject , LessonDetail, FlexDetail, LessonPackage, TestDetail} from './components/subject'
import { PreviewCourse, PreviewLesson, PreviewLessonPackage, TestDetailPreview} from './components/reviewsubject'
import { Profile } from './components/profile'
import { Assignment } from './components/assignment'


import { store } from './store'

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/home', name: 'home', component: Welcome },
        { path: '/', name: 'login', component: Login, meta: { layout: 'auth-layout' }},
        { path: '/login', name: 'login', component: Login, meta: { layout: 'auth-layout' }},
        { path: '/register', name: 'register', component: Register, meta: { layout: 'auth-layout' }},
        { path: '/inbox', name: 'inbox', component: Inbox},
        { path: '/liveclass', name: 'liveclass', component: LiveClass},
        { path: '/assignment', name: 'assignment', component: Assignment, meta: { layout: 'empty-layout' }},
        { path: '/reward/:name_challenge', name: 'reward', component: Reward},
        { path: '/ranking', name: 'ranking', component: Ranking},
        { path: '/profile', name: 'profile', component: Profile},
        { path: '/lesson/:class_id/:course_id-:module_id-:lesson_id-:lesson_package_id-:item_id', name: 'lesson_detail', component: LessonDetail, meta: { layout: 'empty-layout' },  props: true,},
        { path: '/flex', name: 'flex_detail', component: FlexDetail, meta: { layout: 'empty-layout' },  props: true,},
        { path: '/test-module', name: 'test_detail', component: TestDetail, meta: { layout: 'empty-layout' },  props: true,},
        { path: '/subject/:course_id/:class_id', name: 'subject', component: Subject,  meta: { layout: 'empty-layout' }},
        { path: '/lesson-package/:class_id/:course_id-:module_id-:lesson_id', name: 'lesson-package', component: LessonPackage,  meta: { layout: 'empty-layout' }, props: true},
        { path: '/preview-course', name: 'PreviewCourse', component: PreviewCourse,  meta: { layout: 'preview-layout' }},
        { path: '/preview-lesson', name: 'preview-lesson', component: PreviewLesson,  meta: { layout: 'preview-layout' }},
        { path: '/preview-lesson-package', name: 'preview-lesson-package', component: PreviewLessonPackage,  meta: { layout: 'preview-layout' },  props: true,},
        { path: '/test-module-preview', name: 'test_detail_preview', component: TestDetailPreview, meta: { layout: 'preview-layout' },  props: true,},

        // { path: '/preview-module/:module_id', name: 'PreviewModule', component: PreviewCourse,  meta: { layout: 'empty-layout' }},

        { path: '/404', name: 'not_found', component: NotFound },
        { path: '/403', name: 'forbidden', component: Forbidden },

        { path: '*', component: { default: NotFound, helper: NotFound }, meta: { layout: 'error-layout' } }
    ],
    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0,
        };
    }
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            //store.dispatch("SET_USER_INFO");
            store.commit('setLoading', true);
            next();
            return
        }
        //next('/register-anomynous')
    }
    //store.commit("setLoading", true);
    if (to.name) {
        // Start the route progress bar.
    }

    next()
});

router.afterEach((to, from) => {
    store.commit('setLoading', false);
});

export default router;