<template>
    <popup-modal ref="popup">
        <div class="list-item-modal">
            <a href="javascript:void(0)" class="close-modal">
                <img @click="closeModal" :src="closeImg" alt="Close">
            </a>
            <div class="content">
                <div class="item-content" v-for="catagorie in list_catagory" :key="catagorie.id">
                    <h4 class="catagorie">
                        {{ catagorie.name }}
                    </h4>
                    <div class="item" v-for="item in catagorie.items" :key="item.id">
                        <div @click="openItem(item)">
                            <img v-if="item.id == learning_item.id" src="/themes/web/assets/images/icon/arrow-left-orange.svg" alt="">
                            <img v-else-if="item.status==1" src="/themes/web/assets/images/icon/checked-item.svg" alt="">

                            <p :class="item.id == learning_item.id ? 'active' : '' ">
                                {{ item.name }} - {{ item.type_code }}
                            </p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal.vue'


export default {
    name: "ConfirmSubmit",
    components: { PopupModal},
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-1.png',
        title: undefined,
        message: undefined, // Main text content
        search: true,
        list_catagory: [],
        learning_item: {},
    }),
    mounted(){

    },
    created(){
       
    },
    methods: {
        show(opts = {}) {
            this.list_catagory = opts.list_catagory
            this.learning_item = opts.learning_item
            this.$refs.popup.open()
        },
        closeModal() {
            this.$refs.popup.close();
        },
        openItem(item){
            this.$emit('openItem', item)
            this.closeModal()
        }
    },
}
</script>

<style scoped>
    .list-item-modal{
        width: 1152px;
        /* height: 624px; */
        color: red;
    }
    .close-modal{
        display: flex;
        justify-self: flex-end;
        justify-content: flex-end;
        right: 0;
        cursor: default;
        top: 0;
    }
    .close-modal img{
        cursor: pointer;
    }

    .content{
        gap: 30px;
        /* display: grid;
        grid-template-columns: repeat(3, 1fr);
        height: auto;
        padding: 26px 48px 80px 48px; */
        display: flex;
        flex-wrap: wrap;
        padding: 80px 48px;
    }

    .content > div {
        color: red;
    }

    .item{
        margin-left: 20px;
        color: var(--Liquorice-Light, #777);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        letter-spacing: 0.32px;
        cursor: pointer;
    }
    .item > div{
        display: flex;
        /* flex-direction: column; */
        /* justify-content: center; */
    }
    .item > div img{
        margin-right: 10px;
    }
    .catagorie{
        color: var(--Jelly-Light, #A560EB);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
        margin-bottom: 8px;
    }
    .item-content{
        width: 30%;
        margin-bottom: 24px;
    }

    p.active{
        color: var(--Pumpkin-Light, #FC7A1B);
    }

</style>