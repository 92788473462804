<template>
    <div id="live-class-item">
        <div class="item-calender-class" v-if="this.live_class.status == 2">
            <div class="calender-class live">
                <div class="day"><span class="dot-green"></span>Live</div>
                <div class="time">
                    <div>{{ formatUnitTime(live_class.time_start_str) }}</div>
                    <div>-</div>
                    <div>{{ formatUnitTime(live_class.time_end_str) }}</div>
                </div>
            </div>
            <div class="details-class">
                <p class="name-subject">{{ live_class.class_name }}</p>
                <!-- <p class="name-class">Class: <span>{{ live_class.class_name }}</span></p> -->
                <p class="name-teacher">Teacher: <span>{{ live_class.teacher }}</span></p>
            </div>
            <button class="igs-btn" @click="btnJoinClass(live_class.start_url)">Join Class</button>
        </div>

        <div class="item-calender-class" v-if="this.live_class.status == 1">
            <div class="calender-class comming">
                <div class="day">{{ getPeriodOfDay(live_class.time_start) }}</div>
                <div class="time">
                    <div>{{ formatUnitTime(live_class.time_start_str) }}</div>
                    <div>-</div>
                    <div>{{ formatUnitTime(live_class.time_end_str) }}</div>
                </div>
            </div>
            <div class="details-class">
                <p class="name-subject">{{ live_class.class_name }}</p>
                <!-- <p class="name-class">Class: <span>{{ live_class.class_name }}</span></p> -->
                <p class="name-teacher">Teacher: <span>{{ live_class.teacher }}</span></p>
            </div>
            <div class="status comming">
                <p class="">Start in</p>
                <p style="white-space: nowrap;"><countdown :live_class = "live_class" :key="live_class.id"/></p>
            </div>
        </div>

        <div class="item-calender-class" v-if="this.live_class.status == 4">
            <div class="calender-class finish">
                <div class="day">{{ getPeriodOfDay(live_class.time_start) }}</div>
                <div class="time">
                    <div>{{ formatUnitTime(live_class.time_start_str) }}</div>
                    <div>-</div>
                    <div>{{ formatUnitTime(live_class.time_end_str) }}</div>
                </div>
            </div>
            <div class="details-class finish">
                <p class="name-subject">{{ live_class.class_name }}</p>
                <!-- <p class="name-class">Class: <span>{{ live_class.class_name }}</span></p> -->
                <p class="name-teacher">Teacher: <span>{{ live_class.teacher }}</span></p>
            </div>
            <div class="">
                <p class="status finish">FINISHED</p>
            </div>
        </div>
        <!-- <div class="empty-class">
            There is no class today.
        </div> -->
    </div>
</template>

<script>
import axios from "axios";
import Countdown from '../element/Countdown.vue';

export default {
    name: "LiveClassItem",
    components: { Countdown},
    props: {
        live_class:{
            start_time:'',
            end_time:'',
            start_date:'',
            end_date:'',
            name_live_class:'',
            class:'',
            teacher:'',
            status:0
        }
    },

    data() {
        return {
            timeNow:'',
        };
    },

    mounted() {
        // this.startCountdown();
    },

    created() {
        // const currentTime = new Date();
        // const startTime = new Date(`${this.live_class.start_date}T${this.live_class.start_time}`);
        // const endTime = new Date(`${this.live_class.end_date}T${this.live_class.end_time}`);
        // if((currentTime - endTime) > 0){
        //     this.ObjectEdit.status = 3
        // } 
        // if((currentTime - startTime) < 0 && (currentTime - endTime) < 0){
        //     this.ObjectEdit.status = 2
        // }
        // if((currentTime - startTime) > 0 && (currentTime - endTime) < 0){
        //     this.ObjectEdit.status = 1
        // }
    },

    methods: {
        getPeriodOfDay(timestamp) {
            const date = new Date(timestamp * 1000);
            const hour = date.getHours();

            // Giá trị giờ tối đa của buổi sáng là 12, giá trị giờ tối thiểu của buổi chiều là 13
            if (hour < 12) {
                return 'Morning';
            } else {
                return 'Afternoon';
            }
        },
        formatUnitTime(timeVal){
            // const timeString = timeVal;
            // const [hour, minute, second] = timeString.split(':');
            // const time = new Date();
            // time.setHours(hour);
            // time.setMinutes(minute);
            // time.setSeconds(second);

            // const options = { hour: '2-digit', minute: '2-digit', hour12: true };
            // const formattedTime = time.toLocaleTimeString('en-US', options);
            // return formattedTime


            var date = new Date(timeVal);
            var options = { hour: 'numeric', minute: 'numeric', hour12: true };
            var time = date.toLocaleTimeString('en-US', options);
            return time
        },
        btnJoinClass(url){
            window.open(url)                      
        },
    },
    computed: {
		ObjectEdit: {
			get() {
				return this.live_class
			},
			set(val) {
				this.$emit('update:live_class', val)
			}
		},
	},
}
</script>

<style scoped>
    .empty-class{
        font-weight: 700;
        font-size: 24px;
        color: #EAD4FF;
    }
    .details-class{
        color: #4C4C4C;
        width: 100%;
        letter-spacing: 0.02em;
    }
    .details-class.finish{
        color: #CFCFCF;
    }
    .details-class span{
        font-weight: 500;
    }
    .name-subject{
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.02em;
        margin-bottom: 4px;
    }
    .name-class{
        font-weight: 100;
        font-size: 16px;
        margin-bottom: 2px;
    }
    .name-teacher{
        font-weight: 100;
        font-size: 16px;
    }
    .status.finish{
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.05em;
        margin-right: 28px;
        color: #CFCFCF;
    }
    .status.comming p:nth-child(1){
        font-weight: 400;
        font-size: 16px;
    }
    .status.comming p:nth-child(2){
        font-weight: 700;
        font-size: 20px;
    }
    .status.comming{
        margin-right: 28px;
        color: #FDD20D;
        text-align: center;
        letter-spacing: 0.02em;
    }
    #live-class-item{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .item-calender-class{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .calender-class.finish{
        background: #777777;
    }
    .calender-class.finish .time{
        color: #777777;
    }
    .calender-class.live{
        background: #FF3D54;
    }
    .calender-class.live .day{
        color: #FFFFFF
    }
    .calender-class.comming{
        background: #FFC317;
    }
    .calender-class{
        width: 90px;
        height: 90px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2px;
        margin-right: 24px;
    }
     .calender-class .day{
        font-weight: 700;
        font-size: 14px;
        color: #000000;
        margin-bottom: 5px;
    }
     .calender-class .time{
        font-weight: 700;
        font-size: 16px;
        color: #334155;
        width: 86px;
        height: 64px;
        background-color: #FFFFFF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .dot-green{
        display: inline-block;
        background: #58CC02;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 4px;
    }
</style>
