<template>
    <section class="four-zero-four">
        <div class="text-center" style="padding: 50px 0px">
        <h4>404. That an error.</h4>
        <p>The requested URL was notfound on this server.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: "ErrorLayout",

    components: {

    },

    computed: {
        isLoading() {
            return this.$store.getters.isLoading
        },
        apiLoading() {
            return this.$store.getters.apiLoading
        },
        alert() {
            return this.$store.getters.alert
        }
    },

    created() {
        //this.$store.dispatch("SET_USER_INFO");
        //this.$store.commit("setLoading", true);
    },
}
</script>
<style scoped>
.loading {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    height: 100%;
    width: 100%;
    overflow: visible;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 1;
}

.spinner {
    animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
    0% {
        stroke: #4285F4;
    }
    25% {
        stroke: #DE3E35;
    }
    50% {
        stroke: #F7C223;
    }
    75% {
        stroke: #1B9A59;
    }
    100% {
        stroke: #4285F4;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}
</style>
