<template>
  <div id="lesson">
    <div class="webview">
      <iframe
        allow="microphone *;clipboard-read *; clipboard-write *"
        :src="test.content_url"
        frameborder="0"
        allowfullscreen
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  getListItem,
  saveCurrentItem,
  updateItem,
} from "../../models/dashboard";

export default {
  name: "FlexDetail",
  components: {},
  title: "FlexDetail",

  props: ["test"],

  data() {
    return {
      current_categories: {},
      list_catagory: [],
      course_id: this.$route.params.course_id,
      unit_id: this.$route.params.unit_id,
      item_id: this.$route.params.item_id,
      lesson_id: this.$route.params.lesson_id,
      learning_item: {},
      lesson_name: "",
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    getItemInfo() {
      axios
        .all([
          updateItem({
            id: this.learning_item.id,
            item_type: this.learning_item.type_code,
          }),
        ])
        .then(
          axios.spread((response) => {
            if (response.data.code != 0) {
              return;
            }
          })
        )
        .catch((error) => {
          console.log("sai");
        });
    },

    saveCurrentItem(itemId) {
      formData.append("item_id", itemId);
      saveCurrentItem(formData)
        .then((response) => {
          that.$store.commit("setLoading", false);
          if (response.data.code != 0) {
            return;
          }
        })
        .catch((response) => {
          that.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style scoped>
#lesson {
  margin-right: 24px;
  overflow: auto;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.header {
  height: 32px;
  margin-bottom: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.webview {
  box-shadow: 4px 0px 24px rgba(0, 0, 0, 0.25);
  background: #f8fafc;
  border-radius: 24px;
  /* height: 738px; */
  height: calc(100% - 48px);
  overflow: hidden;
  margin-top: 24px;
}
.igs-btn {
  height: 32px;
  width: 47px;
  background: #ffffff;
}
.igs-btn.btn-menu {
  width: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.path {
  letter-spacing: 0.02em;
  color: #00afff;
  font-weight: 500;
  font-size: 16px;
}
.path-1 {
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 16px;
  color: #f8fe5f;
}
</style>
