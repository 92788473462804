<template>
    <div id="register">
        <div class="left">
            <div class="top">
                <img src="/themes/web/assets/images/icon/icon-avatar.svg" alt="">
            </div>
            <div class="bottom">
                <div class="info">
                    <p>NEW PROMOTION</p>
                    <p>25% OFF</p>
                    <p>When refer a new friend signup for a new account.</p>
                </div>
                <div class="btn-more">Learn more</div>
            </div>
        </div>
        <div class="right">
            <h3>Signin</h3>
            <div class="form-group">
                <div class="label">Username or Email Address</div>
                <div class="form">
                    <img class="icon-search" src="/themes/web/assets/images/icon/icon-mail.svg" alt="">
                    <input type="text" name="username" placeholder="Email Address">
                </div>
            </div>
            <div class="form-group">
                <div class="parent-label">
                    <div class="label">Password</div>
                    <div class="p-forgor-password">Forgot Password?</div>
                </div>
                <div class="form">
                    <img class="icon-search" src="/themes/web/assets/images/icon/icon-lock.svg" alt="">
                    <input type="password" name="password" placeholder="••••••••">
                    <img class="icon-search" src="/themes/web/assets/images/icon/icon-eye.svg" alt="">
                </div>
            </div>
            <div class="btn btn-login">
                Sign in
            </div>
            <div class="btn btn-free">
                Start free trial, no account needed
            </div>
            <div class="bottom">
                <span>Don’t have an account?</span><span>Create new account</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Register",
        data() {
            return {
                
            };
        },

        created() {
            
        },

    }
</script>

<style scoped>
    #register{
        display: flex;
        justify-content: space-between;
        width: 960px;
        height: 720px;
        background: #FFFFFF;
        border-radius: 56px;
        overflow: hidden;
    }
    #register .right{
        width: 50%;
        overflow: hidden;
        padding: 48px;
    }
    #register .left{
        width: 50%;
        overflow: hidden;
    }
    .top img{
        height: 560px;
    }
    .btn-more{
        border: 1px solid #CFCFCF;
        box-shadow: 4px 4px 0px #F2F3F4;
        border-radius: 48px;
        background: #FFFFFF;
        height: 52px;
        padding: 0 24px;
        font-weight: 700;
        font-size: 16px;
        color: #191715;
        display: flex;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
    }
    .bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        margin-top: 26px;
        gap: 24px;
    }
    .info p:nth-child(1){
        color: #6E6B7B;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }
    .info p:nth-child(2){
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: 700;
        font-size: 24px;
    }
    .info p:nth-child(3){
        color: #6E6B7B;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
    }
    .form-group{
        background: #FFFFFF;
        margin-bottom: 16px;
    }
    .form-group .form{
        height: 56px;
        width: 384px;
        border-radius: 56px;
        border: 1px solid #EAEAEA;
        background: #F8FAFC;
        display: flex;
        align-items: center;
        padding-right: 20px;
        padding-left: 10px;
    }
    .form-group .form input{
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        margin-left: 10px;
        background: #F8FAFC;
    }
    .form-group .label{
        color: #6E6B7B;
        font-size: 16px;
        letter-spacing: 0.02em;
        margin-bottom: 8px;
    }
    .parent-label{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .p-forgor-password{
        color: #F97316;
        letter-spacing: 0.02em;
        font-size: 16px;
        cursor: pointer;
    }
    .right h3{
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 24px;
    }
    .btn{
        width: 100%;
        height: 52px;
        border: 1px solid #CFCFCF;
        box-shadow: 4px 4px 0px #F2F3F4;
        border-radius: 48px;
        color: #191715;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
    }
    .btn-login{
        background: #F8FE5F;
        margin-bottom: 24px;
    }
    .btn-free{
        margin-bottom: 16px;
    }
    .right .bottom{
        display: flex;
        justify-content: center;
        gap: 8px;
    }
    .right .bottom span:nth-child(1){
        color: #403D3D;
        letter-spacing: 0.02em;
        font-weight: 400;
        font-size: 16px;
        white-space: nowrap;
    }
    .right .bottom span:nth-child(2){
        color: #A560EB;
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        text-decoration-line: underline;
        white-space: nowrap;
    }
</style>
