<template>
    <div id="blank-page">
        <div class="show-destop">
            <router-view></router-view>
        </div>
        <div class="show-mobile">
            Download App
        </div>
        <div class="loading" id="loading" v-if="isLoading">
            <img class="spinner" src="/themes/web/assets/images/icon/loadding.png" alt=""/>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Empty",

        data() {
            return {};
        },
        components: {
            
        },
        computed: {
            isLoading() {
                return this.$store.getters.isLoading
            },
            user_info() {
                return this.$store.state.user_info;
            }
        },

        methods: {
            fetch() {

            },
        }
    }
</script>

<style scoped>
    #blank-page{
        /* display: flex;
        justify-content: center;
        align-items: center; */
        /* height: 100%;
        width: 100%; */
        /* height: fit-content; */
    }
</style>