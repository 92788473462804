<template>
    <component v-bind:is="layout"></component>
</template>
<script>
    import LoadingLayout from "./layout/LoadingLayout";
    import ErrorLayout from "./layout/ErrorLayout";
    import EmptyLayout from './layout/EmptyLayout';
    import PreviewLayout from './layout/PreviewLayout';
    import AppLayout from './layout/AppLayout.vue';
    import AuthLayout from './layout/AuthLayout.vue';




    export default {
        computed: {
            layout() {
                return this.$route.meta.layout || this.$store.getters.layout
            }
        },
        components: {
            'error-layout': ErrorLayout,
            'loading-layout': LoadingLayout,
            'empty-layout': EmptyLayout,
            'preview-layout': PreviewLayout,
            'app-layout': AppLayout,
            'auth-layout': AuthLayout,
            // define as many layouts you want for the application
        }
    }
</script>
