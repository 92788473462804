<template> 
    <div>
        <div class="daily-challenge-box-item can-claim" v-if="mission.status==1">
            <div class="status">
                COMPLETED
            </div>
            <div class="title">
                {{ $t(mission.desc) }}
            </div>
            <div class="percent">
                {{ mission.process }} / {{ mission.max_process }} WORDS
            </div>
            <div style="padding: 0 16px;">
                <div class="progress full">
                    <div class="progress-load"
                        v-bind:style="{ width: (100*mission.process/mission.max_process) + '%' }"></div>
                </div> 
                <button class="igs-btn" @click="claim">
                    <span>Collect {{ mission.key_reward }}</span>
                    <span><img src="/themes/web/assets/images/icon/key-challenges.svg" alt="" style="height: 25px;width: 25px;"></span>
                </button>
            </div>
        </div>


        <div class="daily-challenge-box-item claimed" v-if="mission.status==2">
            <div class="status">
                COMPLETED
            </div>
            <div class="title">
                {{ $t(mission.desc) }}
            </div>
            <div class="percent">
                {{ mission.process }} / {{ mission.max_process }} PROBLEMS
            </div>
            <div style="padding: 0 16px; display: flex; align-items: center; flex-direction: column;">
                <div class="progress ">
                    <div class="progress-load-zero"
                        v-bind:style="{ width: (100*mission.process/mission.max_process) + '%' }"></div>
                </div> 
                <button class="igs-btn"><span>Collected</span></button>
            </div>
        </div>

        <div class="daily-challenge-box-item todo" v-if="mission.status==0">
            <div class="status">
                TODO
            </div>
            <div class="title">
                {{ $t(mission.desc) }}
            </div>
            <div class="percent">
                {{ mission.process }} / {{ mission.max_process }} MINUTES
            </div>
            <div style="padding: 0 16px;">
                <div class="progress">
                    <div v-if="mission.process==0" class="progress-load-zero"
                        v-bind:style="{ width: '9%' }"></div>
                    <div v-else class="progress-load"
                        v-bind:style="{ width: (100*mission.process/mission.max_process) + '%' }"></div>
                </div> 
                <button class="igs-btn">
                    <span>Collect {{ mission.key_reward }}</span>
                    <span class="block"><img src="/themes/web/assets/images/icon/key-challenges.svg" alt="" style="height: 25px;width: 25px;"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import {mapGetters} from 'vuex'
    import {claimChestDailyChallenge, claimKeyDailyChallenge} from "../../models/reward";
    import {soundClick, soundGetReward, soundClaimKey} from '../../utils/utils';

    export default {
        name: "DailyChallengeMissionItem",

        props: ['mission','isLoading'],

        computed: {
            ...mapGetters([
                'user_info'
            ], () => {
                if (this.user_info) {
                    return this.user_info
                }

                return {
                    gem: 0
                }
            }),
            loading: {
                get() {
                    return this.isLoading
                },
                set(val) {
                    this.$emit('update:isLoading', val)
                }
            }
        },

        data() {
            return {

            };
        },

        created() {

        },

        methods: {
            claim() {
                const that = this;

                that.getSoundClick();
                // that.getSoundClaimKey();
                that.loading = true;
                claimKeyDailyChallenge({
                    id: that.mission.id,
                    //is_raw: true
                }).then((response) => {
                    
                    if (response.data.code != 0) {
                        return;
                    }

                    const new_key_collect = response.data.results.key_collect || 0;
                    const new_key_reward = response.data.results.key_reward || 0;
                    const new_mission_id = response.data.results.id || that.mission.id;
                    const new_mission_status = response.data.results.status || that.mission.status;
                    const new_challenges = response.data.results.challenge || [];

                    //that.mission = response.data.results.status;

                    this.$emit('updateNewMission', new_key_collect, new_mission_id, new_mission_status)
                    this.$emit('updateNewChallenge', new_challenges, new_key_collect)

                    const gem_txt = new_key_reward > 0 ? '+' + new_key_reward : new_key_reward;
                    that.loading = false;
                    $('#congratulation_lock').text(gem_txt);

                    // that.getSoundGetReward();
                    that.getSoundClaimKey();

                    $('#getRewardedLockModal').modal();
                }).catch(({response}) => {
                    console.error(response);
                });
            },

            getSoundClick() {
                soundClick();
            },

            getSoundGetReward() {
                soundGetReward();
            },

            getSoundClaimKey() {
                soundClaimKey();
            }
        }
    }
</script>

<style scoped>
    .daily-challenge-box-item{
        width: 100%;
        height: 266px;
        border-radius: 24px;
        width: 180px;
        overflow: hidden;
    }

    .daily-challenge-box-item.can-claim{
        background: radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%);
        border: 4px solid #8549BA;
    }
    .daily-challenge-box-item.claimed{
        background: radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%);
        border: 4px solid #8549BA;
    }
    .daily-challenge-box-item.todo{
        background: #FFFFFF;
        border: 4px solid #FC7A1B;
    }
    .daily-challenge-box-item.can-claim .status, .daily-challenge-box-item.claimed .status{
        background: #8549BA;
        border-radius: 20px 0px 30px 0;
        height: 30px;
        width: fit-content;

        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        text-align: center;

        padding: 6px 16px 0 16px;
    }


    .daily-challenge-box-item.todo .status{
        background: #FC7A1B;
        border-radius: 20px 0px 30px 0;
        height: 30px;
        width: fit-content;

        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        text-align: center;

        padding: 6px 16px 0 16px;
    }
    .title{
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 0 16px;
        /* margin-top: 30px;
        margin-bottom: 35px; */
        height: 75px !important;
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        overflow: hidden;
    }
    .daily-challenge-box-item.todo .title{
        color: #777777;
    }
    .daily-challenge-box-item.can-claim .title, .daily-challenge-box-item.claimed .title{
        color: #FFFFFF;
    }
    .percent{
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #DDDDDD;
        margin-bottom: 17px;
    }
    .progress {
        position: relative;
        opacity: 1;
        background: #8549BA;
        border-radius: 16px;
        height: 16px;
        width: 100%;
        padding: 2px;
        /* margin: 14px 0 12px 0; */
        margin-bottom: 25px;
    }
    .progress-load {
        background: #FC7A1B;
        border-radius: 16px;
        transition: none 0s ease 0s;
        height: 100%;
    }

    .progress-load-zero {
        background: #EAD4FF;
        border-radius: 16px;
        transition: none 0s ease 0s;
        height: 100%;
    }

    .progress.zero{
        background: #EAD4FF;
    }

    .progress.full .progress-load{
        background: #F8FE5F;
    }
    .igs-btn{
        width: 100%;
        height: 46px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.02em;
        border: 1px solid #191715;
        padding: 0 23px;
        display: flex;
    }

    .daily-challenge-box-item.todo .igs-btn{
        color: #A560EB;
        background: #FFFFFF;
    }

    .daily-challenge-box-item.claimed .igs-btn, .daily-challenge-box-item.todo .igs-btn{
        background: #F8FAFC;
        color: #CFCFCF;
        box-shadow: 4px 4px 0px #DDDDDD;
        border: 1px solid #DDDDDD;
        width: fit-content;
    }
    .igs-btn span{
        align-items: center;
        justify-content: center;
        height: -webkit-fill-available;
        display: flex;
    }
    
    .igs-btn .block{
        background: #F8FAFC;
        mix-blend-mode: luminosity;
    }
</style>
