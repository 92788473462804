<template>
    <transition name="fade">
        <div class="popup-modal modal" v-if="isVisible">
            <div class="window">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Modal",
    data: () => ({
        isVisible: false,
    }),

    methods: {
        open() {
            this.isVisible = true
        },

        close() {
            this.isVisible = false
        },
    },
}
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.popup-modal {
    background: linear-gradient(180deg, rgba(56, 23, 112, 0.5) 0%, rgba(127, 44, 134, 0.5) 100%);
    backdrop-filter: blur(12px);
    z-index: 99;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: unset;
    position: fixed;
}

.window {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    /* border-radius: 56px; */
    border-radius: 24px;
    /* position: relative; */
}
</style>
