<template>
    <div id="nav-bar">
        
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "NavBar",

        data() {
           return{
                
           }
        },

        computed: {
           
        },

        created() {

        },
        mounted() {
            
        },
        methods: {

        }
    }
</script>

<style scoped>
    #nav-bar{
        
    }
</style>
