<template>
    <div id="inbox">
        <div class="direction">
            <div class="name-page">
                <img class="icon-home" src="/themes/web/assets/images/icon/icon-home.svg" alt="">
                <img class="icon-arow" src="/themes/web/assets/images/icon/icon-arrow-right.svg" alt="">
                <p class="title">Inbox</p>
            </div>
            <p class="name">All Inboxes</p>
        </div>
        <div class="view">
            <!-- <div class="comming">
                <div class="text-container">
                    <h1 class="fade-text">Your Fading Text Goes Here</h1>
                </div>
            </div> -->
            <img src="/themes/web/assets/images/icon/banner-comming-soon.png" alt="">
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Inbox",
    title: 'Inbox',

    data() {
        return {
         
        };
    },

    created() {
        this.$store.commit('setMenuActive', 'inbox');
    },

    methods: {
       
    }
}
</script>

<style scoped>
    .view{
        display: flex;
        justify-content: center;
    }
    .comming{
        
    }
    /* h2{
        color: linear-gradient(180deg, #FFFFFF 53.5%, rgba(153, 153, 153, 0) 100%);
        font-size: 50px;
    } */
</style>
