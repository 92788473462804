import {get, post} from "../APIGateWay";

export async function getAssignments(params = {}) {
    return await get(params, '/courses/list-assignment')
}

export class AssignmentEntity {
    constructor() {
        this.id = 0;
        this.name = "";
        this.course_id = 0;
        this.course_item_id = 0;
        this.course_component_id = 0;
        this.module_id = 0;
        this.module_name = "";
        this.lesson_id = 0;
        this.lesson_name = "";
        this.status = EAssignmentStatus.NOT_STARTED;
        this.start_at = null;
        this.updated_at = null;
        this.end_date = null;
        this.extend_date = null;
        this.core = "";
        this.class_id = 0;
        this.parent_id = 0;
        this.subject = "";
        this.module_order = 0,
        this.lesson_order = 0
    }
}

export const EAssignmentStatus = Object.freeze({
    UNKNOW: "",
    NOT_STARTED: "not-started",
    INPROGRESS: 'Processing',
    ON_GOING: "Todo",
    SUBMITED: "Submitted",
    COMPLETED: "Completed",
    NEED_RESUBMIT: "NeedToResubmit",
    OVERDUE: "Overdue"
  });

  export const BUTTONS_FILTER = Object.freeze({
    INCOMPLETE: 1,
    COMPLETED: 2,
    ALL: 3,
});

export const StatusPriority = Object.freeze({
    "not-started": 6,
    'Processing': 3,
    "Todo": 4,
    "Submitted": 5,
    "Completed": 5,
    "NeedToResubmit": 2,
    "Overdue": 1
});