var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"lesson"}},[_c('list-item',{ref:"listItem",on:{"openItem":_vm.openItem}}),_c('div',{staticClass:"header"},[_c('div',{staticClass:"btn-menu small",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.showListItem()}}},[(_vm.showMenu)?_c('img',{attrs:{"src":"/themes/web/assets/images/icon/icon-menu.svg","alt":""}}):_c('img',{attrs:{"src":"/themes/web/assets/images/icon/icon-x.svg","alt":""}}),(!_vm.showMenu)?_c('div',{staticClass:"tools"},_vm._l((_vm.catagory.items),function(item){return _c('div',{key:item.id},[_c('div',{staticStyle:{"display":"flex","gap":"10px","margin-bottom":"10px"},on:{"click":function($event){return _vm.openItem(item)}}},[(item.id == _vm.learning_item.id)?_c('img',{attrs:{"src":"/themes/web/assets/images/icon/arrow-left-orange.svg","alt":""}}):_vm._e(),_c('p',{class:item.id == _vm.learning_item.id ? 'active' : ''},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0):_vm._e()]),_c('div',[_c('button',{class:_vm.learning_item.index != 0
            ? 'igs-btn small'
            : 'igs-btn small inactive',staticStyle:{"margin-right":"8px"},on:{"click":function($event){_vm.learning_item.index != 0 ? _vm.pre(_vm.learning_item) : ''}}},[_c('img',{attrs:{"src":"/themes/web/assets/images/icon/arrow-left.svg","alt":""}})]),_c('button',{class:_vm.learning_item.index + 1 != _vm.current_categories.items.length
            ? 'igs-btn small'
            : 'igs-btn small inactive',on:{"click":function($event){_vm.learning_item.index + 1 != _vm.current_categories.items.length
            ? _vm.next(_vm.learning_item)
            : ''}}},[_c('img',{attrs:{"src":"/themes/web/assets/images/icon/arrow-right.svg","alt":""}})])]),_c('img',{staticClass:"icon-arow",attrs:{"src":"/themes/web/assets/images/icon/icon-arrow-right.svg","alt":""}}),_c('p',{staticClass:"path"},[_vm._v(_vm._s(_vm.current_categories.name))]),_c('img',{staticClass:"icon-arow",attrs:{"src":"/themes/web/assets/images/icon/icon-arrow-right.svg","alt":""}}),_c('p',{staticClass:"path-1"},[_vm._v(_vm._s(_vm.learning_item.name))])]),_c('div',{staticClass:"webview"},[_c('iframe',{attrs:{"allow":"microphone *;clipboard-read *; clipboard-write *","src":_vm.learning_item ? _vm.learning_item.content : '',"frameborder":"0","allowfullscreen":"","width":"100%","height":"100%"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }