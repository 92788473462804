<template>
    <popup-modal ref="popup">
        <div class="theme-modal" v-if="type==1">
            <div class="header">
                <div class="title">
                    <p>About this course</p>
                    <h4>{{course_name}}</h4>
                </div>
                <a href="javascript:void(0)" class="close-modal">
                    <img @click="closeModal" :src="closeImg" alt="Close">
                </a>
            </div>
            <div class="content">
                <div class="left">
                    <!-- <h4>{{ course_name }}</h4> -->
                    <p class="p-2">This Course contains: <br>- {{ total_lessons }} {{ total_lessons > 1 ? 'Lessons' : 'Lessons' }}  <br>- {{ total_tests }} {{total_tests > 1 ? 'Tests' : 'Test'}}</p>
                    <p class="p-3" :style="{ whiteSpace: 'pre-wrap' }">{{ course_desc }}</p>
                </div>
                <div class="right">
                    <img :src="course_img" alt="">
                </div>
            </div>
        </div>
        <div class="theme-modal" v-else>
            <div class="header">
                <div class="title">
                    <h4>{{ type==2 ? 'Module Description' : 'Lesson Description'}} </h4>
                </div>
                <a href="javascript:void(0)" class="close-modal">
                    <img @click="closeModal" :src="closeImg" alt="Close">
                </a>
            </div>
            <div class="content">
                <div class="desc-module">
                    <p :style="{ whiteSpace: 'pre-wrap' }">{{ module_desc }}</p>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal1.vue'


export default {
    name: "ConfirmSubmit",
    components: { PopupModal},
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-2.svg',
        theme: {},

        total_lessons: 0,
        total_tests: 0,
        subject_name: '',
        course_name: '',
        course_img: '',
        course_desc: '',
        type: null,
        module_desc: '',
    }),
    mounted(){

    },
    created(){
       
    },
    methods: {
        show(opts = {}) {
            this.total_lessons = opts.total_lessons
            this.total_tests = opts.total_tests
            this.subject_name = opts.subject_name
            this.course_name = opts.course_name
            this.course_img = opts.course_img
            this.course_desc = opts.course_desc
            this.module_desc = opts.desc
            this.type = opts.type
            this.$refs.popup.open()
        },
        closeModal() {
            this.$refs.popup.close();
        }
    },
}
</script>

<style scoped>
    .left h4{
        color: var(--Liquorice-Dark, #4C4C4C);
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.48px;
        margin-bottom: 8px;
    }
    .theme-modal{
        border-radius: 24px;
        width: 1044px;
        /* height: 562px; */
        padding-bottom: 50px;
        padding-top: 0;
        padding-right: 0;
        /* position: relative; */
    }
    .title p{
        color: #334155 !important;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.24px;
    }
    .title h4{
        color: #334155;
        font-size: 32px;
        font-weight: 700;
        margin-top: 10px;
    }
    .close-modal{
        cursor: default;
    }
    .close-modal img{
        cursor: pointer;
    }
    .header{
        border-radius: 24px 24px 0 0;
        padding: 24px;
        background: #FFF;
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
        display: flex;
        backdrop-filter: blur(12px);
        justify-content: space-between;
    }
    .content{
        display: flex;
        justify-content: space-between;
        padding: 24px;
        gap: 16px;
        overflow: auto;
        height: 50%;
    }
    .right img{
        width: 300px;
        border-radius: 8px;
        border: 1px solid #777;
        box-shadow: 8px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 8px 0px 0px #4F4F4F;
        /* height: 400px; */
        height: 200px;
    }
    .p-1{
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.32px;
        margin-bottom: 16px;
    }
    .p-2{
        color: #777;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.24px;
        margin-bottom: 8px;
    }
    .h4-1{
        color:#4C4C4C;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.48px;
        margin-bottom: 8px;
    }
    .p-3{
        color: #4C4C4C;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    }
    .desc-module{
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #4C4C4C;
        font-weight: lighter !important;
    }
</style>