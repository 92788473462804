import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {getLanguage} from "./utils/utils";

Vue.use(VueI18n);

const messages = {
    vi: $.getJSON({'url': process.env.VUE_APP_LANG_URL + "vi-VN.json", 'async': false}).responseJSON,
    en: $.getJSON({'url': process.env.VUE_APP_LANG_URL + "en-EN.json", 'async': false}).responseJSON,
};
console.log(messages);
const lang = getLanguage();
const i18n = new VueI18n({
    locale: lang,
    messages,
    fallbackLocale: lang,
});

export default i18n
