<template>
    <popup-modal ref="popup">
        <div class="loading" id="loading" v-if="isLoading">
            <img class="spinner" src="/themes/web/assets/images/icon/loadding.png" alt="" />
        </div>

        <div class="change-avatar-modal">
            <a href="javascript:void(0)" class="close-modal">
                <img @click="closeModal" :src="closeImg" alt="Close">
            </a>
            <h2  class="title">{{ title }}</h2>
            <div class="content">
                <div class="left">
                    <div class="img-avatar">
                        <img ref="avatar_url" :src="user.avatar_url" alt="">
                    </div>
                    <div class="action-button">
                        <button class="igs-btn white" @click="uploadAvatar">Upload</button>
                        <input @change="handleFileInputChange"
                            type="file" 
                            id="avatar-item-upload" 
                            name="avatar" 
                            accept="image/*"  
                            style="display: none;"
                        />

                        <button @click="updateAvatar()" class="igs-btn">Save</button>
                    </div>
                </div>
                <div class="right">
                    <div v-for="avatar in listAvatar" :key="avatar.id" @click="selectAvatar(avatar)" :class="avatar.id  == user.avatar_id? 'item-avatar checked' : 'item-avatar'">
                        <img :src="avatar.url" alt="">
                    </div>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal.vue'
import {updateAvatar} from '../../../models/profile'

export default {
    name: "ChangeAvatar",
    components: { PopupModal },
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-1.png',
        title: undefined,
        message: undefined, // Main text content
        search: true,
        listAvatar: [],
        user: {},
        imageUrl:'',
        file:'',
        isLoading: false,
    }),
    mounted(){

    },
    created(){
        this.user = { ...this.user_info };
    },
    computed: {
        user_info() {
            return this.$store.state.user_info;
        },
    },
    methods: {
        show(opts = {}) {
            this.title = "Change Avatar"
            this.listAvatar = opts.listAvatar
            this.$refs.popup.open()
        },
        closeModal() {
            this.$refs.popup.close();
        },
        uploadAvatar(event){
            $("#avatar-item-upload").click()
        },
        handleFileInputChange(event){
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                this.user.avatar_url = URL.createObjectURL(file);
                this.user.avatar_id = -1
            };

            reader.readAsDataURL(file);
            this.file = file;
        },
        selectAvatar(avatar){
            this.user.avatar_id = avatar.id;
            this.user.avatar_url = avatar.url;
            this.$refs.avatar_url.setAttribute("src", avatar.url)
        },
        updateAvatar(){
            const that = this;
            const formData = new FormData();

            formData.append('avatar_id', this.user.avatar_id);
            formData.append('avatar_url', this.user.avatar_url);
            this.isLoading = true

            updateAvatar({
                avatar_id: this.user.avatar_id,
                avatar_url: this.user.avatar_url,
                extension_file: 'png',
            }).then((response) => {
                that.isLoading = false
                const time = new Date().getTime();
                that.user_info.avatar_id = response.data.results.avatar_id;
                that.user_info.avatar_url = response.data.results.avatar_url + '?v=' + time;
            })
        },
    },
}
</script>

<style scoped>
    .change-avatar-modal{
        width: 810px;
        height: 490px;
        position: relative;
        padding: 24px 48px;
    }
    .title{
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
    }
    
    .close-modal{
        position: absolute;
        display: flex;
        justify-self: flex-end;
        justify-content: flex-end;
        /* width: 424px; */
        right: 0;
        cursor: default;
        top: 0;
    }
    .close-modal img{
        cursor: pointer;
    }
    .content{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
    }
    .left{
        width: 200px;
        margin-top: 16px;
    }
    .left .img-avatar{
        width: 200px;
        height: 200px;
        border: 4px solid #5B46B1;
        border-radius: 56px;
        overflow: hidden;
    }
    .left .img-avatar img{
        width: 100%;
        height: 100%;
    }
    .igs-btn{
        width: 160px;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .left .igs-btn:nth-child(2){
        background: #F8FE5F;
        border: 1px solid #E3E87A;
        box-shadow: 0px 0px 16px #F8FE5F, 4px 4px 0px #E3E87A;
    }
    .action-button{
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }
    .right{
        width: 100%;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        height: fit-content;
        height: 390px;
        overflow: auto;
        padding-top: 16px;
    }
    .right .item-avatar{
        width: 112px;
        height: 113px;
        background: #FFFFFF;
        border-radius: 24px;
        border: 1px solid #ccc;
        cursor: pointer;
    }
    .right .item-avatar img{
       width: 100%;
       height: 100%;
    }
    .item-avatar.checked::after{
        position: absolute;
        content: url(/public/themes/web/assets/images/icon/checked-avatar.svg);
        top: -10px;
        right: -10px;
        z-index: 3;

    }
    .item-avatar.checked{
        position: relative;
    }
</style>