<template>
    <div class="modal fade modal-flex" id="getRewardedModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="formErrorModal" aria-hidden="true">
        <div class="modal-dialog" style="width: 477px; max-width: 477px;max-height: 455px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title">Congratulation</h2>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    <div class="modal-close" @click="ok()">
                        <img src="/themes/web/assets/images/icon/icon-closed-1.png" alt="">
                    </div>
                </div>
                <div class="modal-body">
                    <div class="welcome-back">
                        <div class="welcome-back-content">
                            <!-- <span id = "congratulation_gem" class="modal-title" style="vertical-align: bottom;">+0</span> <i class="lisa-icon-gem-large-2" style="vertical-align: middle;"></i> -->
                            <span class="modal-title" style="vertical-align: bottom; font-size: 16px; font-weight: 400;">Collect <span id="congratulation_gem">0</span> gems successfully!</span>
                        </div>
                        <div class="welcome-back-img">
                            <img src="/themes/web/assets/images/collect_diamond.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-main-sm m-auto" @click="ok" style="padding: 8px 40px;">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "GetRewardModal",
  props: ['gems'],
  methods: {
    ok(){
        location.reload();
    }
    
  }  
}
</script>

<style scoped>
    .modal-content{
        /* background: url(/public/themes/igs/images/paper-flower.svg) no-repeat top; */
        background-color: white;
        border-radius: 56px;
        /* height: 350px; */
        width: 300px;
    }
    #staticBackdropLabel{
        font-weight: 900;
    }
</style>
