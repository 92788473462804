<template>
    <div id="daily-bonus">
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 24px;">
            <div>
                <h4 class="title">Collect your Gems every day</h4>
                <p class="desc">Log in 7 days in a row, your rewards will grow.</p>
            </div>
            <div class="time-to-collect">
                {{ time_next_bonus_txt }} to collect {{ gem_next_bonus }} Gems
            </div>
        </div>
        <div class="list-bonus">
            <div v-for="(bonus, index) in bonuses" v-bind:key="index" class="bonus-item">
                <DailyBonusItem :bonus="bonus" :today_id="today_id" @updateNewBonus="updateNewBonus"></DailyBonusItem>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from 'vuex'
import DailyBonusItem from "./DailyBonusItem.vue";
import {getDailyBonus, getDailyChallenge} from "../../models/reward";
import {convertSecondToText, soundClick} from "../../utils/utils";
import {claimDailyBonus} from "../../models/reward";

export default {
    name: "DailyBonus",
    components: {DailyBonusItem},
    title: 'DailyBonus',
    props: ['bonus_noti'],
    data() {
        return{
            bonuses: '',
            today_id: '',
            time_next_bonus: '',
            time_next_bonus_txt: '',
            gem_next_bonus:0
        }
    },

    watch: {
        time_next_bonus: {
            handler(value) {

                if (value > 0) {
                    setTimeout(() => {
                        this.time_next_bonus--;

                        this.time_next_bonus_txt = convertSecondToText(this.time_next_bonus) || '';
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return this.user
        }),
        bonus_completed_active:{
            get() {
                return this.bonus_noti
            },
            set(val) {
                this.$emit('update:bonus_noti', val)
            }
        }
    },

    created() {
        this.fetch();
    },

    methods: {
        fetch() {
            const that = this;

            axios.all([
                getDailyBonus(),
            ]).then(axios.spread((response) => {
                that.isLoading = false;

                if (response.data.code != 0) {
                    return;
                }

                that.bonuses = response.data.results.daily_bonus || [];
                that.today_id = response.data.results.today_id || '';
                that.time_next_bonus = response.data.results.time_next_bonus || 0;
                that.time_next_bonus_txt = convertSecondToText(that.time_next_bonus) || '';
                
                var index_today = response.data.results.daily_bonus.findIndex(el => 
                    el.id == response.data.results.today_id
                );
                that.gem_next_bonus = response.data.results.daily_bonus[index_today+1].gem;
                // console.log(that.gem_next_bonus);
                // console.log(that.bonuses );
            }));
        },

        updateNewBonus(){
            console.log("this.bonus_completed_active",this.bonus_completed_active)
            this.bonus_completed_active = (this.bonus_completed_active==0)?0:this.bonus_completed_active-1;
        },

        claim() {
            const that = this;

            that.getSoundClick();

            that.$store.commit("setLoading", true);

            claimDailyBonus().then((response) => {
                that.$store.commit("setLoading", false);

                //if (response.data.code != 0) {
                //  return;
                //}

                //that.bonus.status = response.data.results.status || '';

                const gem_reward = response.data.results.gem_reward || 0;
                const new_gem = response.data.results.update_gem || 0;

                that.user_info.gem = new_gem;

                const gem_txt = gem_reward >= 0 ? '+' + gem_reward : '-' + gem_reward;

                //that.getSoundClaimTreasure();

                $('#congratulation_gem').text(gem_txt);
                $('#dailyBonusModal').modal("hide");
                $('#getRewardedModal').modal();
                this.$emit('updateFirstLogin', 0)
            }).catch((response) => {
                that.$store.commit("setLoading", false);

                console.error(response);
            });
        },

        getSoundClick() {
            soundClick();
        },
    }
}
</script>

<style scoped>
    .content{
        width: 100%;
    }
    .title{
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.02em;
        color: #191715;
        margin-bottom: 8px;
    }
    .desc{
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #191715;
    }
    .time-to-collect{
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        padding: 16px 24px;
        background: #777777;
        border: 1px solid #CFCFCF;
        border-radius: 24px;
        height: 53px;
    }
    .list-bonus{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 12px;
        height: 209px;
    }
    .bonus-item{
        background: #EAD4FF;
        border: 1px solid #DDDDDD;
        border-radius: 24px;
        overflow: hidden;
    }
</style>
