<template>
    <popup-modal ref="popup">
        <div class="theme-modal">
            <div class="header">
                <div class="title">
                    <h4>Course Selection</h4>
                </div>
                <a href="javascript:void(0)" class="close-modal">
                    <img @click="closeModal" :src="closeImg" alt="Close">
                </a>
            </div>
            <div class="content">
                <div v-for="(course, index) in courses" :key="course.class_id" v-if="course.is_started == 1" 
                :class="{ 
                    'course-item': true, 
                    'current': course.class_id == id_current_class && id_current_class == temp_id, 
                    // 'current1': id_current_class != temp_id && id_current_class1 = id_current_class, 
                    'active': course.class_id == temp_course.class_id,
                    'current1': course.class_id == temp_id,
                }"
    
                @click="selectCourse(course)">
                    <div class="left">
                        <div class="img">
                            <img :src="course.illustration_url" alt="">
                        </div>
                        <div class="desc">
                            <p>{{ course.program_name }}</p>
                            <h5>{{ course.course_name }}</h5>
                        </div>
                    </div>
                    <div class="pacing" v-if="false">
                        <div class="progress">
                            <div class="status"></div>
                            <div class="status-1"></div>
                            <div class="status-2"></div>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <button :disabled="temp_course.class_id != temp_id && temp_course.class_id != undefined ? false : true" :class="temp_course.class_id != temp_id && temp_course.class_id != undefined ? 'btn-change-course active' :  'btn-change-course'" @click="changeCourse(temp_course)">SWITCH COURSE</button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal1.vue'


export default {
    name: "ConfirmSubmit",
    components: { PopupModal},
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-2.svg',
        courses: [],
        id_current_class: 0,
        id_current_class1: 0,
        temp_course: {},
        temp_id: 0,
    }),
    mounted(){

    },
    created(){
    },
    computed: {
        current_course() {
            return this.$store.getters.current_course
        },
    },
    methods: {
        show(opts = {}) {
            this.courses = opts.courses
            this.id_current_class = opts.id_current_class
            this.id_current_class1 = opts.id_current_class
            

            // this.temp_course = opts.courses.find(course => course.course_id === opts.id_current_course);
            this.temp_id = opts.id_current_class
            this.temp_course = opts.courses

            this.$refs.popup.open()
        },
        closeModal() {
            this.$refs.popup.close();
        },
        selectCourse(course){
            this.temp_course = course
            this.id_current_class = course.class_id
        },
        changeCourse(course){
            this.$router.push({ path: '/subject/'+course.course_id + '/' + course.class_id })
            this.$refs.popup.close();
            this.$emit('update:courseId', course.course_id);
            this.$emit('update:classId', course.class_id);
        }
    },
}
</script>

<style scoped>
    .pacing .line{
        width: 24px;
        height: 1px;
        /* background: #000; */
        border: 1px dashed #000;
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        z-index: 2;
    }
    .pacing .status{
        position: absolute;
        border-radius: 1000px;
        background: #FF9839;
        width: 16px;
        height: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    .pacing .status-1{
        position: absolute;
        top: 50%;
        z-index: 1;
        border-radius: 0px 0px 1000px 1000px;
        background: #F00;
        width: 16px;
        height: 24px;
    }
    .pacing .status-2{
        position: absolute;
        bottom: 50%;
        z-index: 1;
        border-radius: 1000px 1000px 0px 0px;
        background: #58CC02;
        width: 16px;
        height: 24px;
    }
    .pacing .progress{
        width: 16px;
        height: 64px;
        padding: 32px 0px 8px 0px;
        border-radius: 1000px;
        background: rgba(0, 175, 255, 0.15);
        position: relative;
    }
    .content{
        max-height: 320px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 24px;
        overflow: auto;
    }
    .course-item .left{
        display: flex; 
        align-items: center; 
        gap: 8px;
    }
    .course-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 486px;
        height: 96px;
        margin-bottom: 16px;
        padding: 8px 24px;
        border-radius: 24px;
        border-radius: 24px;
        border: 2px solid var(--cloud-light, #DDD);
    }
    .course-item.active{
        border: 2px solid var(--pumpkin-light, #FC7A1B);
        background: radial-gradient(109.13% 100% at 50.00% 100.00%, #FFE9DA 0%, #FC7A1B 100%);
    }
    .course-item.current{
        border: 2px solid var(--blueberry-light, #02A6E7) !important;
        background: radial-gradient(109.13% 100% at 50.00% 100.00%, #DBEAFE 0%, #02A6E7 100%) !important;
    }
    .course-item.current1{
        background: radial-gradient(109.13% 100% at 50% 100%, rgba(219, 234, 254, 0.1) 0%, rgba(2, 166, 231, 0.1) 100%);
        border: 1px solid #02A6E7;
    }
    .course-item .desc p{
        color: var(--liquorice-dark, #4C4C4C);
        font-size: 14px;
        font-weight: 500;
    }
    .course-item .desc h5{
        color: var(--liquorice-dark, #4C4C4C);
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.4px;
        width: 326px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .course-item.active .desc p, .course-item.current .desc p{
        color: var(--creme-light, #FFF);
    }
    .course-item.active .desc h5, .course-item.current .desc h5{
        color: #FFF;
    }
    .course-item .img{
        width: 80px;
        height: 80px;
        overflow: hidden;
    }
    .course-item .img img{
        width: 100%;
        height: 100%;
    }
    .theme-modal{
        border-radius: 50px;
        width: 1044px;
        /* height: 562px; */
        padding-top: 0;
        padding-right: 0;
    }
    .title p{
        color: #334155 !important;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.24px;
    }
    .title h4{
        color: #334155;
        font-size: 32px;
        font-weight: 700;
    }
    .close-modal{
        cursor: default;
    }
    .close-modal img{
        cursor: pointer;
    }
    .header{
        border-radius: 24px 24px 0 0;
        padding: 24px;
        background: #FFF;
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
        display: flex;
        backdrop-filter: blur(12px);
        justify-content: space-between;
    }
    .right img{
        width: 300px;
        border-radius: 8px;
        border: 1px solid #777;
        box-shadow: 8px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 8px 0px 0px #4F4F4F;
        height: 400px;
    }
    .p-1{
        color: #777;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.32px;
        margin-bottom: 16px;
    }
    .p-2{
        color: #777;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.24px;
        margin-bottom: 8px;
    }
    .h4-1{
        color:#4C4C4C;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.48px;
        margin-bottom: 8px;
    }
    .p-3{
        color: #4C4C4C;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
    }
    .btn-change-course{
        border-radius: 48px;
        border: 1px solid var(--cloud-dark, #CFCFCF);
        background: var(--cloud-light, #DDD);
        box-shadow: 4px 4px 0px 0px #CFCFCF;
        padding: 16px 24px;

        color: #191715;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        cursor: not-allowed;
    }
    .btn-change-course.active{
        border: 1px solid var(--pumpkin-dark, #CF5410);
        background: var(--pumpkin-light, #FC7A1B);
        box-shadow: 4px 4px 0px 0px #CF5410;
        color: #FFF;
        cursor: pointer;
    }
    .bottom{
        box-shadow: 0px -8px 24px 0px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(12px);
        background: rgba(255, 255, 255, 0.80);
        padding: 16px 24px;
        border-radius: 0 0 24px 24px;
        display: flex;
        justify-content: flex-end;
    }
</style>