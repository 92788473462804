<template>
    <div>
        <div id="side-bar">
            <div @click="profileUser()" id="account">
                <div>
                    <div style="background: #fff; border-radius: 15px; margin-bottom: 5px; padding: 5px;">
                        <div style="border: 3px solid #DBEAFE; border-radius: 15px;">
                            <img class="avatar" :src="user_info.avatar_url" alt="">
                        </div>
                    </div>
                    <div class="group-grade">
                        <div class="grade">
                        
                        </div>
                        <div class="number-grade">
                            <p>{{ user_info.grade }}</p>
                        </div>
                    </div>
                </div>
                <div class="diamond">
                    <img src="/themes/web/assets/images/icon/icon-diamond.svg" alt="">
                    <p>{{ user_info.gem | toCommas  }}</p>
                </div>
            </div>
            <div id="menu">
                <ul>
                    <router-link @click.native="getSoundClick()" class="router-link" :to="{ name: 'home' }">
                        <li :class="menu_active=='home' ? 'nav-link active' : 'nav-link'"  @mouseover="hovered = 1" @mouseleave="hovered = false">
                            <img :src="menu_active=='home' || hovered == 1 ? '/themes/web/assets/images/icon/menu-home-active.svg' : '/themes/web/assets/images/icon/menu-home.svg'" alt="">
                            <p>{{$t('Home')}}</p>
                        </li>
                    </router-link>
                    <router-link @click.native="getSoundClick()" class="router-link" :to="{ name: 'assignment' }" >
                        <li  :class="menu_active=='assignment' ? 'nav-link active' : 'nav-link'" @mouseover="hovered = 4" @mouseleave="hovered = false">
                            <img :src="menu_active=='assignment' || hovered == 4 ? '/themes/web/assets/images/icon/menu-assignment-active.svg' : '/themes/web/assets/images/icon/menu-assignment.svg'" alt="">
                            <p>{{$t('Assignment')}}</p>
                        </li>
                    </router-link>
                    <!-- <router-link @click.native="getSoundClick()" class="router-link" :to="{ name: 'liveclass' }"  >
                        <li :class="menu_active=='liveclass' ? 'nav-link active' : 'nav-link'" @mouseover="hovered = 2" @mouseleave="hovered = false">
                            <img :src="menu_active=='liveclass' || hovered == 2 ? '/themes/web/assets/images/icon/menu-calender-active.svg' : '/themes/web/assets/images/icon/menu-calender.svg'" alt="">
                            <p>{{$t('live_class')}}</p>
                        </li>
                    </router-link> -->
                    <router-link @click.native="getSoundClick()" class="router-link" :to="{ path: '/reward/challenge' }"  >
                        <li :class="menu_active=='challenge' ? 'nav-link active' : 'nav-link'" @mouseover="hovered = 3" @mouseleave="hovered = false">
                            <img :src="menu_active=='challenge' || hovered == 3 ? '/themes/web/assets/images/icon/menu-challenge-active.svg' : '/themes/web/assets/images/icon/menu-challenge.svg'" alt="">
                            <p>{{$t('Challenges')}}</p>
                            <div v-if="user_info.dailychallenge_noti">
                                <div :class="menu_active=='challenge' ? 'notification challenge active' : 'notification challenge'">{{ user_info.dailychallenge_noti }}</div>               
                            </div>   
                        </li>
                    </router-link>
                    <router-link @click.native="getSoundClick()" class="router-link" :to="{ name: 'ranking' }" >
                        <li  :class="menu_active=='ranking' ? 'nav-link active' : 'nav-link'" @mouseover="hovered = 5" @mouseleave="hovered = false">
                            <img :src="menu_active=='ranking' || hovered == 5 ? '/themes/web/assets/images/icon/menu-rank-active.svg' : '/themes/web/assets/images/icon/menu-rank.svg'" alt="">
                            <p>{{$t('Ranking')}}</p>
                        </li>
                    </router-link>
                    <!-- <router-link @click.native="getSoundClick()" class="router-link" :to="{ name: 'inbox' }"  >
                        <li :class="menu_active=='inbox' ? 'nav-link active' : 'nav-link'" @mouseover="hovered = 6" @mouseleave="hovered = false">
                            <img :src="menu_active=='inbox' || hovered == 6 ? '/themes/web/assets/images/icon/menu-inbox-active.svg' : '/themes/web/assets/images/icon/menu-inbox.svg'" alt="">
                            <p>{{$t('Inbox')}}</p>
                            <div v-if="user_info.count_mail_unread">
                                <div :class="menu_active=='inbox' ? 'notification inbox active' : 'notification inbox' ">{{ user_info.count_mail_unread }}</div>               
                            </div>   
                        </li>
                    </router-link> -->
                </ul>
            </div>
        </div>
        <div>
            <profile-modal @showTranscript="handleTranscript" class="" ref="profileUser"></profile-modal>
            <warning-modal class="" ref="warning"></warning-modal>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {soundClick, formatNumber} from "../utils/utils";
    import profileModal from "../components/modal/home/profileModal.vue";
    import warningModal from "../components/modal/home/warningModal.vue";
    import moment from 'moment-timezone';


    export default {
        name: "SideBar",

        components: {profileModal, warningModal},

        data() {
            return{
                hovered: null,
                deviceTimeZone: '',
                show_warning: true
            }
        },

        created() {
          
        },

        mounted(){
            this.getDeviceTimeZone();
            this.checkTimeZone();
        },
 
        computed: {
            menu_active() {
                return this.$store.state.menu_active
            },
            user_info() {
                return this.$store.state.user_info;
            },
        },

        updated: function () {
            this.$nextTick(function () {
                //App.init();
            })
        },

        methods: {
            getDeviceTimeZone() {
                this.deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                console.log(this.deviceTimeZone);
            },

            compareTimeZones(tz1, tz2) {
                const offsetTz1 = moment.tz(tz1).utcOffset();
                const offsetTz2 = moment.tz(tz2).utcOffset();

                if (offsetTz1 === offsetTz2) {
                    return true;
                } else {
                    return false;
                }
            },

            async checkTimeZone() {
                const times = JSON.parse(localStorage.getItem(`warning_timezone_${this.user_info.username}`)) ? JSON.parse(localStorage.getItem(`warning_timezone_${this.user_info.username}`))[this.user_info.username] : 0;
                this.show_warning = this.$store.getters.isShowWarning;
                if (this.user_info.timezone && this.deviceTimeZone && (!this.compareTimeZones(this.user_info.timezone, this.deviceTimeZone)) && times<3 && this.show_warning) {
                    const cfm = await this.$refs.warning.show({
                        lang: this.lang,
                        user_info: this.user_info
                    });
                    if (!cfm) {
                        return false;
                    }
                }
            },

            async handleTranscript(a){
                const cfm = await this.$refs.transcript.show({
                    lang: this.lang,
                    listAvatar : this.listAvatar
                });
                if (!cfm) {
                    return false;
                }
            },
            getSoundClick() {
                soundClick();
            },
            async profileUser() {
                const cfm = await this.$refs.profileUser.show({
                    lang: this.lang,
                });
                if (!cfm) {
                    return false;
                }
            },
            getGem(gem) {
                return formatNumber(gem);
            },
        }
    }
</script>

<style scoped>
    #account{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3px;
        width: 84px;
        height: 90px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 24px;
        position: fixed;
        top: 25px;
    }
    #account .avatar{
        width: 37px;
        height: 40px;
        /* margin-bottom: 5px; */
    }
    #account .grade{
        width: 22px;
        height: 22px;
        background: #02A6E7;
        transform: matrix(0.7, -0.71, 0.7, 0.71, 0, 0);
        border-radius: 5px;
    }
    #account .group-grade{
        position: absolute;
        top: 32px;
        right: 12px;
    }
    #account .number-grade p{
        font-weight: 900;
        font-size: 24px;
        position: absolute;  
        bottom: -4px;
        left: 4px; 
    }
    .diamond{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .diamond p{
       margin-left: 3px;
       color: #000000;
    }
    #side-bar{
        background-color: #28113D;
        display: inline-block;
        margin-left: 6px;
        border-radius: 24px;
        position: fixed;
        /* top: calc(25px + 90px + 100px); */
        /* top: 20%; */
        top: 200px;
        z-index: 3;
    }
    #menu ul{
        text-align: center;
        padding: 15px 0;
        width: 84px;
    }
    #menu .router-link{
        text-decoration: none;
    }
    #menu .nav-link{
        padding: 22px 0;
        position: relative;
    }
    #menu .nav-link p{
        font-size: 12px;
        margin-top: 5px;
        font-weight: 700;
        color: #4C4C4C;
    }
    #menu .nav-link.active{
        filter: drop-shadow(0px 0px 22px #F8FE5F);
    }
    #menu .nav-link.active p{
        color: #F8FE5F;
    }
    #menu .nav-link:hover p{
        color: #F8FE5F;
    }
    #menu .nav-link:hover{
        filter: drop-shadow(0px 0px 22px #F8FE5F);
    }
    #menu .nav-link:hover .notification{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #F04821;
    }
    .notification.active{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #F04821;
    }
    .notification{
        position: absolute;
        font-size: 10px;
        color: #FFFFFF;
        font-weight: 700;
        display: flex;
        align-items: center;
        text-align: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.25);
        justify-content: center;
        background-color: #4C4C4C;
    }
    .notification.inbox{
        top: 17px;
        right: 19px;
    }
    .notification.challenge{
        top: 17px;
        right: 19px;
    }
</style>
