<template>
    <div>
        <div :class="bonus.id == today_id?'daily-bonus-item active active_1':'daily-bonus-item'" v-if="bonus.status == 2">
            <div class="day">{{ $t(`${bonus.desc}`) }}</div>
            <img :src="(imgList.filter(x=> x.day == bonus.desc))[0].url" alt=""/>
            <img class="checked-bonus" src="/themes/web/assets/images/icon/checked-bonus.svg" alt="">
            <div class="reward">+{{ bonus.gem }}</div>
        </div>    
        <div :class="bonus.id == today_id?'daily-bonus-item active':'daily-bonus-item'" v-else-if="bonus.status == 1">
            <div class="day">{{ $t(`${bonus.desc}`) }}</div>
            <img :src="(imgList.filter(x=> x.day == bonus.desc))[0].url" alt=""/>
            <div class="reward">+{{ bonus.gem }}</div>
            
            <div class="btn-open">
                <button class="igs-btn" @click = "claim">Open</button>
            </div>
        </div>    
        <div :class="bonus.desc == 'day_7'?'daily-bonus-item feature day_7':'daily-bonus-item feature'" v-else>
            <div class="day">{{ $t(`${bonus.desc}`) }}</div>
            <img :src="(imgList.filter(x=> x.day == bonus.desc))[0].url" alt=""/>
            <div class="reward">+{{ bonus.gem }}</div>
        </div> 
    </div>
    <!-- <div>
        <div id="daily-bonus-item">
        
        </div>
    </div> -->
</template>

<script>
import {mapGetters} from 'vuex'
import axios from "axios";
import {claimDailyBonus} from "../../models/reward";

export default {
    name: "DailyBonusItem",
    components: {},
    title: 'DailyBonusItem',

    props: ['bonus', 'today_id'],

    data() {
        return{
            imgList: [
                {day:"day_1", url:"/themes/web/assets/images/icon/diamond-bonus-1.svg"},
                {day:"day_2", url:"/themes/web/assets/images/icon/group-diamond.svg"},
                {day:"day_3", url:"/themes/web/assets/images/icon/group-diamond.svg"},
                {day:"day_4", url:"/themes/web/assets/images/icon/box-diamond.svg"},
                {day:"day_5", url:"/themes/web/assets/images/icon/mix-diamond.svg"},
                {day:"day_6", url:"/themes/web/assets/images/icon/box-mix-diamond.svg"},
                {day:"day_7", url:"/themes/web/assets/images/icon/chest-diamond.svg"},
            ]
        }
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return {
                gem: 0
            }
        })
    },

    created() {

    },

    methods: {
        claim() {
            const that = this;

            claimDailyBonus().then((response) => {

                if (response.data.code != 0) {
                    return;
                }

                this.user_info.first_login = 0;

                that.bonus.status = response.data.results.status || '';

                const new_gem = response.data.results.update_gem || 0;
                const gem = new_gem - that.user_info.gem;

                that.user_info.gem = new_gem;
                this.$emit('updateNewBonus')

                const gem_txt = gem > 0 ? '+' + gem : gem;

                $('#congratulation_gem').text(gem_txt);
                $('#getRewardedModal').modal();
            }).catch(({response}) => {
                console.error(response);
            });
        }
    }
}
</script>

<style scoped>
    .daily-bonus-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 100%;
        background: #F8FAFC;
    }
    .daily-bonus-item .day{
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #777777;
        margin-bottom: 16px;
        margin-top: 16px;
    }
    .daily-bonus-item .reward{
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #191715;
        margin-top: 20px;
    }
    .daily-bonus-item.checked{
        background: #EAD4FF;
    }
    .checked-bonus{
        position: absolute;
        top: 90px;
        right: 22px;
    }
    .daily-bonus-item.active .day, .daily-bonus-item.active .reward{
        color: #FFFFFF;
    }
    .daily-bonus-item.active{
        background: radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%);
    }
    .igs-btn{
        height: 35px;
        border: 1px solid #191715;
        padding: 0 16px;
    }
    .btn-open{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
