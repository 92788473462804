<template>
    <footer id="footer" class="d-flex">
        
    </footer>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "FooterElement",

        data() {
            return {
             
            };
        },

        computed: {
            
        },

        created() {

        },

        updated: function () {

        },

        methods: {

        }
    }
</script>

<style scoped>
    
</style>
