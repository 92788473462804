<template>
    <div id="live-class">
        <div class="direction">
            <div class="name-page">
                <img class="icon-home" src="/themes/web/assets/images/icon/icon-home.svg" alt="">
                <img class="icon-arow" src="/themes/web/assets/images/icon/icon-arrow-right.svg" alt="">
                <p class="title">Live Class</p>
            </div>
            <p class="name">All Classes</p>
        </div>
        <div class="content">
            <div class="">
                <div class="content-classes">
                    <div class="header">
                        <p class="day">{{ getDayOfWeek(currentYear, currentMonth, day_select) }}</p>
                        <p class="time">{{ day_select }} {{ month_label }}</p>
                        <p class="number-class" v-if="!is_loading">{{ liveClassData.length }} classes</p>
                    </div>
                    <div class="line"></div>
                    <div class="list-calender-class">
                        <div class="" style="margin: auto" v-if="is_loading">
                            <img class="spinner" src="/themes/web/assets/images/icon/loadding.png" alt="" />
                        </div>
                        
                        <div v-else>
                            <div v-if="liveClassData.length==0" class="empty-class">
                                There is no class today.
                            </div>
                            <div v-else style="gap: 16px;display: flex;flex-direction: column;">
                                <template  v-for="(live_class, index) in liveClassData">
                                    <live-class-item :live_class="live_class" v-bind:key="index" @key="live_class.id"/>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="calender">
                <div class="header">
                    <button class="igs-btn purple small" @click="prevDay">
                        <img src="/themes/web/assets/images/icon/arrow-left.svg">
                    </button>
                    <div>
                        <span>{{ month_label }}</span>
                        <span>{{ currentYear }}</span>
                    </div>
                    <button class="igs-btn small" @click="nextDay">
                        <img src="/themes/web/assets/images/icon/arrow-right.svg">
                    </button>
                </div>
                <div class="calendar-row" style="padding:0 23px">
                    <ul class="calendar-weeks">
                        <li class="calendar-weeks_item">Mon</li>
                        <li class="calendar-weeks_item">Tue</li>
                        <li class="calendar-weeks_item">Wed</li>
                        <li class="calendar-weeks_item">Thu</li>
                        <li class="calendar-weeks_item">Fri</li>
                        <li class="calendar-weeks_item">Sat</li>
                        <li class="calendar-weeks_item">Sun</li>
                    </ul>
                    <div class="calendar-days">
                        <div v-for="(day, index) in calanders" class="day item" v-bind:key="index" 
                            :class="(day_select == day.day && day.currentMonth == currentMonth )?'select':''" @click="day.currentMonth == currentMonth ? changeDate(day) : ''">
                            <div v-if="day.currentMonth == currentMonth && day.isToday == false" :class="checkHasClass(day.day) == 1 ? 'has_class' : ( checkHasClass(day.day) == 4 ? 'finish' : '')" v-bind:key="index">
                                {{ day.day.toString().padStart(2, '0') }}
                            </div>
                            <div v-if="day.currentMonth == currentMonth && day.isToday == true" :class="checkHasClass(day.day) == 1 ? 'day_active has_class' : ( checkHasClass(day.day) == 4 ? 'day_active finish' : 'day_active')" v-bind:key="index*2">
                                {{ day.day.toString().padStart(2, '0') }}
                            </div>
                            <div v-if="day.currentMonth == currentMonth+1" style="color: #cdc3c3;" v-bind:key="index*3">
                                {{ day.day.toString().padStart(2, '0') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import axios from "axios";
    import LiveClassItem from './LiveClassItem.vue';
    import { getLiveClass } from "../../models/live_class";

    export default {
        name: "LiveClass",
        title: "Live Class",
        components: { LiveClassItem },
        data() {
            const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ]
            const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
            const date = new Date()
            const currentYear = date.getFullYear()
            const currentMonth = (date.getMonth() + 1)
            const month_labels = months[currentMonth - 1]
            const current_date = date.getDate()
            const calanders = this.getCalanders(currentMonth, currentYear)
            return{
                month_label: month_labels,
                currentYear: currentYear,
                currentMonth : currentMonth,
                months: months,
                calanders: calanders,
                days: days,
                day_select:current_date,
                month_select:currentMonth,
                liveClassData:[],
                allLiveCLass: [],
                is_loading: true,
            }
        },
        computed: {
            
        },
        created() {
            this.$store.commit("setMenuActive", "liveclass");
            this.fetch(this.currentMonth,this.currentYear)
        },
        methods: {
            async fetch(m, y){
                this.getLiveClass(m, y, );
            },
            getDayOfWeek(year, month, day) {
                const today = new Date().toLocaleDateString();
                const date = new Date(year, month - 1, day);
                const daysOfWeek = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'];
                const daysOfWeekEN = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
                const dayOfWeekIndex = date.getDay();
                const dayOfWeekEN = daysOfWeekEN[dayOfWeekIndex];
                const dayOfWeek = daysOfWeek[dayOfWeekIndex];
                return date.toLocaleDateString() === today ? 'TODAY' : dayOfWeekEN;
            },
            checkHasClass(day){
                let currentStatus = 0;
                this.allLiveCLass.forEach(item => {
                    let itemDate = new Date(item.time_start * 1000);

                    if (itemDate.getDate() === day && [1, 2, 4].includes(item.status)) {
                        if (item.status === 2 || item.status === 1) {
                            currentStatus = 1;
                        } else if (currentStatus !== 1) {
                            currentStatus = item.status;
                        }
                    }
                });
                return currentStatus;
            },
            changeDate(day){
                const that = this
                that.day_select = day.day
                that.month_select = day.currentMonth

                const live_class = []
                for(var i=0; i<that.allLiveCLass.length; i++){
                    var date = new Date(that.allLiveCLass[i].time_start * 1000); 
                    var d = date.getDate();
                    if(that.day_select == d){
                        live_class.push(that.allLiveCLass[i])
                    }
                    that.liveClassData = live_class
                }
            },
            getLiveClass(month, year){
                const that = this;
                that.is_loading = true
                axios.all([getLiveClass({
                    month,
                    year
                })]).then(
                    axios.spread((response) => {
                        that.allLiveCLass = response.data.results.class ? response.data.results.class : []
                        that.liveClassData = response.data.results.class ? response.data.results.class : []
                        const live_class = []
                        for(var i=0; i<that.liveClassData.length; i++){
                            // const [y, m, d] = that.liveClassData[i].time_start_str.split('-');

                            var date = new Date(that.liveClassData[i].time_start * 1000); 
                            var d = date.getDate();
                            var m = date.getMonth() + 1;
                            var y = date.getFullYear(); 

                            if(month == m && year == y && that.day_select == d){
                                live_class.push(that.liveClassData[i])
                            }
                        }
                        that.liveClassData = live_class
                        that.is_loading = false
                        that.$forceUpdate()
                    })
                );
            },
            prevDay(){
                this.$store.commit("setLoading", true);
                if (this.currentMonth  == 1) {
                    this.currentMonth  = 12;
                    this.currentYear = this.currentYear - 1;
                } else {
                    this.currentMonth  = this.currentMonth  - 1;
                }
                // this.day_select = this.day_select
                this.month_label = this.months[this.currentMonth - 1]
                this.calanders = this.getCalanders(this.currentMonth , this.currentYear)
                this.fetch(this.currentMonth,this.currentYear)
                this.$forceUpdate()
            },
            nextDay(){
                this.$store.commit("setLoading", true);
                if (this.currentMonth  == 12) {
                    this.currentMonth  = 1;
                    this.currentYear = this.currentYear + 1;
                } else {
                    this.currentMonth  = this.currentMonth  + 1;
                }
                // this.day_select = this.day_select
                this.month_label = this.months[this.currentMonth - 1];
                this.calanders = this.getCalanders(this.currentMonth, this.currentYear)
                this.fetch(this.currentMonth,this.currentYear)
                this.$forceUpdate()
            },
            getCalanders(currentMonth , currentYear) {
                const date = new Date();
                const weeks = [];
                const firstDay = this.getFirstDayInMonth(currentMonth-1, currentYear);
                const lastDay = new Date(currentYear, currentMonth, 0).getDate()
                // const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
                const current_date = date.getDate();
                const current_month = date.getMonth() + 1;
                const current_year = date.getFullYear();
                const lastDayMonthBefore = new Date(currentYear, currentMonth - 1, 0).getDate()
                for (let i = firstDay - 1; i >= 1; i--) {
                    weeks.push({
                        day: lastDayMonthBefore-i+1,
                        isToday: false,
                        currentMonth:currentMonth-1,
                        disabled: false
                    });
                }
                for (let i = 1; i <= lastDay; i++) {
                    let isToday = false
                    if (current_year == currentYear && current_month == currentMonth && current_date == i) {
                        isToday = true;
                    }
                    weeks.push({
                        day: i,
                        isToday: isToday,
                        currentMonth:currentMonth,
                        disabled: false,
                    });
                }
                const lastDays = Math.ceil(weeks.length/7)*7 - weeks.length;
                for (let i = 1; i <= lastDays; i++) {
                    weeks.push({
                        day: i,
                        currentMonth:currentMonth+1,
                        disabled: true,
                    });
                }
                return weeks
            },
            getFirstDayInMonth(month, year) {
                const day = new Date(year, month, 1).getDay();
                switch(day){
                    case 0:
                        return 7;
                    case 1:
                        return 1;
                    case 2:
                        return 2;
                    case 3:
                        return 3;
                    case 4:
                        return 4;
                    case 5:
                        return 5;
                    case 6:
                        return 6;
                    default:
                        return 0;
                }
            },
        },
        formatDay(timestamp) {
            var date = new Date(timestamp * 1000);
            var day = date.getDate();
            return day;
        },
    };
</script>
<style scoped>
    .empty-class{
        font-weight: 700;
        font-size: 24px;
        color: #EAD4FF;
    }
    .calender-tomorrow{
        padding: 24px 48px;
    }
    .calender-tomorrow .empty-class{
        font-weight: 700;
        font-size: 24px;
        color: #EAD4FF;
        margin-top: 24px;
    }
    .calender-tomorrow .time, .calender-tomorrow .number-class{
        color: #4C4C4C !important;
    }
    .calender-tomorrow .number-class{
        font-weight: 100 !important;
    }
    .list-calender-class{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .header .day{
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #4C4C4C;
    }
    .header .time{
        letter-spacing: 0.02em;
        color: #FC7A1B;
        font-weight: 700;
        font-size: 32px;
        height: 48px;
        display: flex;
        align-items: center;
    }
    .header .number-class{
        letter-spacing: 0.02em;
        color: #A560EB;
        font-weight: 600;
        font-size: 16px;
    }
    .line{
        width: 100%;
        border: 1px solid #CFCFCF;
        margin: 8px 0 16px;
    }
    /* -------- */
    #live-class .content{
        display: flex;
        justify-content: space-between;
        /* align-items: flex-start; */
        gap: 24px;
        padding-bottom: 20px;
    }
    .content .content-classes{
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        width: 666px;
        padding: 24px 48px 48px;
        overflow-y: auto;
    }
    .content .calender{
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        width: 398px;
        height: fit-content;
        /* padding: 23px; */
    }
    .content .calender .header {
        /* width: 100%; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 34.5px 30px 17px;
    }
    .content .calender .header span:nth-child(1){
        color: #4C4C4C;
        letter-spacing: 0.05rem;
        font-weight: 900;
        font-size: 20px;
    }
    .content .calender .header span:nth-child(2){
        font-weight: 100;
        color: #4C4C4C;
        font-size: 20px;
        margin-left: 5px;
    }
    .calender-tomorrow{
        width: 666px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 24px;
        height: 193px;
        margin-top: 16px;
    }
    .calendar-row{
        padding: 20px;
    }
    .calendar-row ul{
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: center;
    }
    .calendar-row .calendar-days{
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: center;
    }
    .calendar-row li, .day {
        width: calc(100%/7);
        font-size: 18px;
        font-weight: 700;
    }
    .calendar-row .calendar-weeks li{
        color: #777777;
        font-weight: 700;
        cursor: default;
    }
    .calendar-row .calendar-days .day{
        z-index: 1;
        cursor: pointer;
        position: relative;
        margin-top: 30px;
        color: #777777;
    }
    .calendar-row .calendar-days .item::before{
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    .calendar-row .calendar-days .item:has(.day_active)::before{
        z-index: -2;
        /* background: #FFE9DA; */
        border-radius: 8px;
        border: 1px solid #FC7A1B; 
    }
    .calendar-row .calendar-days .item:has(.has_class)::before{
        border-radius: 8px;
        z-index: -2;
        background: #FFE8D7;
    }
    .calendar-row .calendar-days .item:has(.finish)::before{
        border-radius: 8px;
        z-index: -2;
        background: #CFCFCF;
    }
    .calendar-row .calendar-days .select ,  .calendar-row .calendar-days .select .has_class{
        color: #fff !important;
    }
    .calendar-row .calendar-days .finish{
        color: #fff;
    }
    .calendar-row .calendar-days .has_class{
        color: #FC7A1B;
    }
    .calendar-row .calendar-days .select::before{
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background: #FC7A1B !important;
        z-index: -1;
        border-radius: 8px;
    }
    .calendar-row .calendar-days .select:has(.day_active)::before{
        background: #FC7A1B;
        z-index: -1;
        border-radius: 8px;
    }
    .calendar-col {
        width: calc(100%/7);
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        color: #777777;
    }
    
</style>
