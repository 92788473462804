<template>
    <popup-modal ref="popup">
        <div class="noti-modal">
            <a href="javascript:void(0)" class="close-modal">
                <img style="width: 36px;" @click="closeModal" :src="closeImg" alt="Close">
            </a>
            <div class="content">
                <div class="left">
                    <div class="popup-noti" v-if="true">
                        <h5>Notifications</h5>
                        <div class="search">
                            <div>
                                <img class="icon-search" src="/themes/web/assets/images/icon/icon-search.svg" alt="">
                                <input @keyup="keyUpSearch()" class="form-search" type="text"  placeholder="Search a notification"/>
                            </div>
                        </div>
                        <div class="noti-new">
                            <h6>New</h6>
                            <div v-if="false" class="list-noti">
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti last">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="noti-new-empty">
                                No new notifications
                            </div>
                        </div>
                        
                        <div class="noti-old">
                            <h6>Older</h6>
                            <div class="list-noti">
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti"> 
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="header">
                        <div style="display: flex; align-items: center; gap: 16px">
                            <div class="avatar-noti"> 
                                <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                            </div>
                            <div>
                                <p class="name">Admin</p>
                                <p class="ago">5 minutes ago</p>
                            </div>
                        </div>
                        <div class="btn-mark">
                            <p>Mark a read</p>
                        </div>
                    </div>
                    <div class="body-noti">
                        <div v-if="false" class="noti-main">
                            <h4>Subject: Important Update: Changes to Grade 3 Online English Language Arts Schedule</h4>
                            <p>
                                Dear Parents and Guardians,
                                We hope this message finds you well. We would like to inform you about some important changes to the schedule of the Grade 3 Online English Language Arts (ELA) classes. We recognize the significance of providing a smooth and effective learning experience for your children, and we are committed to making necessary adjustments to ensure their continued progress.
                                Effective immediately, the Grade 3 ELA class schedule will be modified as follows:
                                Class Days: The classes will now be held on Mondays, Wednesdays, and Fridays instead of the previously scheduled Tuesdays and Thursdays. This change will help us create a more evenly distributed learning pattern throughout the week.
                                Class Duration: Each session will be extended by 15 minutes, making the new class duration 60 minutes in total. This extension will allow us to dedicate more time to cover the curriculum thoroughly and engage students in meaningful discussions and activities.
                                Revised Timing: The new class timings will be from 10:00 AM to 11:00 AM, ensuring a consistent start and end time for all Grade 3 ELA sessions. We believe that this adjustment will help establish a predictable routine for your children, promoting a sense of structure and focus during their online learning experience.
                                We understand that changes to the schedule can impact your daily routines, and we apologize for any inconvenience this may cause. However, we firmly believe that these modifications will enhance the quality of our online ELA classes and contribute to your child's educational growth.
                                We kindly request you to take note of these changes and update your calendars accordingly. The revised schedule will be effective starting from the upcoming week, and our dedicated teachers will be ready to implement the new timings and provide an enriching learning experience for all Grade 3 students.
                                If you have any questions or concerns regarding these schedule changes, please do not hesitate to reach out to us. We value your feedback and are committed to working closely with you to ensure a successful learning journey for your child.
                                Thank you for your understanding and cooperation. Together, we can make this transition as smooth as possible and continue to foster a love for the English Language Arts in our Grade 3 students.
                                Sincerely,
                                [Your Name][School/Organization Name]
                            </p>
                        </div>
                        <div v-else class="empty-noti">
                            <h4>You're all caught up!</h4>
                            <p>Check back soon for updates and alerts.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal2.vue'


export default {
    name: "ConfirmSubmit",
    components: { PopupModal},
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-1.png',
        title: undefined,
        message: undefined, // Main text content
        search: true,
    }),
    mounted(){

    },
    created(){
       
    },
    methods: {
        show(opts = {}) {
            this.title = "Search User"
            this.$refs.popup.open()
        },
        closeModal() {
            this.$refs.popup.close();
        }
    },
}
</script>

<style scoped>
    .noti-new-empty{
        padding: 16px 24px;
        border-bottom: 1px solid var(--Cloud-Light, #DDD);
        background: var(--Creme-Dark, #F8FAFC);
        color: var(--Squid-Light, #403D3D);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 15.6px */
        letter-spacing: 0.24px;
    }
    .body-noti{
        padding: 24px;
        overflow-y: auto;
        height: 600px;
    }
    .body-noti .noti-main h4{
        color: var(--Squid-Light, #403D3D);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
        margin-bottom: 24px;
        line-height: 120%; 
    }
    .body-noti .noti-main p{
        color: var(--Squid-Light, #403D3D);
        font-size: 14px;
        font-weight: 400;
        line-height: 200%;
        letter-spacing: 0.28px;
        font-style: normal;
    }
    .body-noti .empty-noti {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80%;
    }
    .body-noti .empty-noti h4{
        color: var(--Squid-Light, #403D3D);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 36px */
        letter-spacing: 0.48px;
    }
    .body-noti .empty-noti p{
        color: var(--Squid-Light, #403D3D);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0.32px;
    }
    .btn-mark{
        border-radius: 48px;
        border: 1px solid var(--squid-black, #191715);
        background: var(--Pistatrio-Light, #F8FE5F);
        box-shadow: 4px 4px 0px 0px #191715;
        padding: 5px 16px;
        cursor: pointer;
    }
    .btn-mark:active{
        position: relative;
        box-shadow: none;
        top: 4px;
        left: 4px;
    }
    .btn-mark p{
        color: var(--squid-black, #191715);
        font-size: 14px;
        font-weight: 700;
    }
    .right .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        border-bottom: 1px solid var(--Cloud-Light, #DDD);
        margin-bottom: 8px;
    }
    .noti-modal{
        position: relative;
        width: 1184px;
        height: 714px;
        background: var(--Creme-Dark, #F8FAFC);
        border-radius: 24px;
        padding: 24px 32px 24px 24px;
    }
    .close-modal{
        position: absolute;
        display: flex;
        justify-self: flex-end;
        justify-content: flex-end;
        right: 0;
        cursor: default;
        top: 0;
    }
    .close-modal img{
        cursor: pointer;
    }
    .content .left{
        border-radius: 24px;
        background: var(--Creme-Light, #FFF);
        width: 320px;
        height: 670px;
        padding: 24px 0px;
        overflow: hidden;
    }
    .content .right{
        border-radius: 24px;
        background: var(--Creme-Light, #FFF);
        width: 792px;
        height: 670px;
        overflow: hidden;
    }
    .content {
        display: flex;
        justify-content: space-between;
        gap: 16px;
    }
    .noti-old .list-noti{
        overflow-y: auto;
        max-height: 400px;
    }
    .list-noti .content-noti{
        color: var(--Squid-Light, #403D3D);
        font-size: 12px;
        font-weight: 200;
        letter-spacing: 0.24px;
    }
    .name{
        color: var(--Squid-Light, #403D3D);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
    }
    .ago{
        color: var(--Liquorice-Light, #777);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.24px;
    }
    .item-noti{
        cursor: pointer;
        gap: 16px;
        padding: 16px 24px;
        display: flex;
        justify-content: space-between;
    }
    .item-noti:hover{
        border-bottom: 1px solid var(--Cloud-Light, #DDD);
        background: var(--Jelly-Very-Light, #EAD4FF);
    }
    .avatar-noti{
        border-radius: 1000px;
        width: 50px;
        height: 40px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        background-color: red;
    }
    .header .avatar-noti{
        width: 40px;
    }
    .avatar-noti img{
        width: 100%;
        height: 100%;
    }
    .noti-new h6, .noti-old h6{
        color: var(--Liquorice-Light, #777);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.32px;
        padding: 0 24px;
    }
    .noti-old h6{
        margin-top: 24px;
    }
    .popup-noti{
        width: 320px;
        align-items: flex-start;
        border-radius: 24px;
        background: var(--Creme-Light, #FFF);
        z-index: 3;
    }
    .popup-noti h5{
        color: var(--Jelly-Light, #A560EB);
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.48px;
        padding: 0 24px;
        margin-bottom: 24px;
    }
    .number-noti{
        color: #FFF;
        font-size: 8px;
        font-weight: 700;

        border-radius: 24px;
        background: var(--Linear-Orange, linear-gradient(180deg, #FF9E44 41.67%, #FF891C 62.5%));
        display: inline-flex;
        padding: 2px 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        
        position: absolute;
        top: 0;
        right: 0;
    }
    .item-noti.last{
        border-bottom: 1px solid var(--Cloud-Light, #DDD);
    }
    .search > div{
        height: 32px;
        display: flex;
        border-radius: 48px;
        border: 1px solid var(--Cloud-Dark, #CFCFCF);
        background: var(--Creme-Light, #FFF);
        align-items: center;
        padding: 0 16px;
    }
    .search .icon-search{
        width: 16px;
        height: 16px;
        margin-right: 24px;
    }
    .search input{
        border: none;
        outline: none;

        color: var(--Liquorice-Light, #777);
        font-size: 12px;
        font-weight: 400;
    }
    .search{
        padding: 0 24px;
        margin-bottom: 24px;
    }
    .form-search{
        width: 85%;
        color: var(--squid-black, #1D1D1F);
        font-size: 14px;
        font-weight: 600;
        background: #FFF;
    }
</style>