import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './components/App.vue'
import router from './router'
import {store} from './store'
import mixinTitle from './utils/mixins/mixinTitle'
import i18n from './i18n'
import Axios from 'axios'
import {API_URL} from "./constant";
import {getCookie} from './APIGateWay'

Vue.use(VueRouter);
Vue.mixin(mixinTitle);
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = API_URL;

Date.prototype.customDate = function(datetime, timezone) {
  console.log(datetime, 123);
  if(datetime == null){
    return "null"
  }
  const utcDate = new Date(datetime + 'Z');

  const options = {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit', 
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
  };
   // Định dạng ngày giờ theo múi giờ chỉ định
   const localDateStr = new Intl.DateTimeFormat('en-US', options).format(utcDate);
   const [date, time] = localDateStr.split(', ');
   const [month, day, year] = date.split('/');
 
   // Trả về định dạng "mm/dd/yyyy hh:mm AM/PM"
   const formattedDate = `${month}/${day}/${year} ${time}`;
   return formattedDate;
};

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    store.commit('setPreviousRoute', from);
  }
  next();
});

// Vue.config.productionTip = false
const app = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: {App},
  template: '<App/>',
  mounted() {
      // addCmsEvent(this.$store)
  }
});

window.addEventListener('message', function (e) {
  // Get the sent data
  console.log("main.js")
      if(e.origin === process.env.VUE_APP_CMS_URL){
          const decoded = JSON.parse(e.data);
          const token = getCookie('_wid')
          if(token){
            document.cookie = `_wid=${decoded.access_token}; path=/`;
          }
          store.commit("SET_CMS_PREVIEW_STATE", true)
      }
  // If you encode the message in JSON before sending them,
  // then decode here
  // const decoded = JSON.parse(data);
  });


export default app