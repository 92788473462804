<template>
    <div id="challenge">
        <div class="direction">
            <div class="name-page">
                <img class="icon-home" src="/themes/web/assets/images/icon/icon-home.svg" alt="">
                <img class="icon-arow" src="/themes/web/assets/images/icon/icon-arrow-right.svg" alt="">
                <p class="title">Challenges</p>
            </div>
            <p class="name">All Tasks</p>
        </div>
        <div v-if="false" class="content">
            <div class="navigator">
                <router-link :class="name_challenge == 'challenge' ? 'navi-item active' : 'navi-item'" :to="{path:'/reward/challenge'}">
                    <div>
                        <img src="/themes/web/assets/images/icon/key-challenges.svg" alt="">
                    </div>
                    <p class="name-navi">DAILY CHALLENGES</p>
                    <p class="noti">{{ challenge_noti }}</p>
                </router-link>
                   
                <router-link :class="name_challenge == 'misson' ? 'navi-item active' : 'navi-item'" :to="{path:'/reward/misson'}">
                    <div>
                        <img src="/themes/web/assets/images/icon/diamond-misson.svg" alt="">
                    </div>
                    <p class="name-navi">MISSIONS</p>
                    <p class="noti">{{ mission_noti }}</p>
                </router-link>

                <router-link :class="name_challenge == 'daily-bonus' ? 'navi-item active' : 'navi-item'" :to="{path:'/reward/daily-bonus'}">
                    <div>
                        <img src="/themes/web/assets/images/icon/diamond-bonus.svg" alt="">
                    </div>
                    <p class="name-navi">DAILY BONUS</p>
                    <p class="noti">{{ bonus_noti }}</p>
                </router-link>
            </div>
            <div class="router-content">
                <Challenge v-if="name_challenge == 'challenge'" :challenge_noti.sync="challenge_noti"></Challenge>
                <Misson v-if="name_challenge == 'misson'" :mission_noti.sync="mission_noti"></Misson>
                <DailyBonus v-if="name_challenge == 'daily-bonus'" :bonus_noti.sync="bonus_noti"></DailyBonus>
            </div>
        </div>
        <div>
            <img src="/themes/web/assets/images/icon/banner-comming-soon.png" alt="">
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Challenge from "./Challenge.vue";
import Misson from "./Misson.vue";
import DailyBonus from "./DailyBonus.vue";
import {getChallengeNoti} from '../../models/reward'


export default {
    name: "Reward",
    components: {Challenge, Misson, DailyBonus},
    title: 'Reward',

    data() {
        return{
            challenge_noti:0,
            mission_noti:0,
            bonus_noti:0
        }
    },

    mounted() {

    },

    computed: {
        name_challenge(){
            return this.$route.params.name_challenge ?  this.$route.params.name_challenge : 'challenge'
        }
    },

    created() {
        this.$store.commit('setMenuActive', 'challenge');
        this.fetch()
    },

    methods: {
        fetch() {
            const that = this;

            axios.all([
                getChallengeNoti(),
            ]).then(axios.spread((response) => {
                that.isLoading = false;

                if (response.data.code != 0) {
                    return;
                }
                // console.log(response)
                that.challenge_noti = response.data.results.ChallengeNoti;
                that.mission_noti = response.data.results.MissionNoti;
                that.bonus_noti = response.data.results.BonusNoti;
            }));
        },
    }
}
</script>

<style scoped>
    .content{
        width: 100%;
        height: 50px;
    }
    .navigator{
        display: flex;
        height: 72px;
        align-items: end;
    }
    .navi-item{
        display: flex;
        height: 64px;
        padding: 0 24px;
        background: #fff;
        border-color: #CFCFCF;
        box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25), 0px -2px 12px #FFFFFF, inset 0px -4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 20px 20px 0px 0px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        text-decoration: none;
    }
    .navi-item.active{
        height: 72px;
        background: #A560EB;
        box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
        border-color: #FFFFFF;
        border: 1px solid #fff;
        border-width: 1px 1px 0px 1px;
    }
    .navi-item .name-navi{
        letter-spacing: 0.02em;
        color: #777777;
        font-weight: 700;
        font-size: 16px;
        margin-left: 16px;
    }
    .navi-item>div{
        width: 48px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .navi-item.active .name-navi{
        color: #FFFFFF;
    }
    .navi-item.active .noti{
        background: #FFFFFF;
        color: #FF3D54;
    }
    .router-content{
        width: 1088px;
        background: #FFFFFF;
        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 24px 24px 24px;
        padding: 24px 48px;
    }
    .noti{
        background: #FF3D54;
        width: 31px;
        height: 31px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-left: 8px;
    }
</style>
