<template>
    <div id="misson">
        <div>
            <h4 class="title">Missions</h4>
            <p class="desc">Complete all the missions to get rewards!</p>

            <div class="misson-list">
                <div class="misson-item">
                    <div v-for="(achievement, index) in achievements" v-bind:key="index">
                        <MissonItem :achievement="achievement" @updateNewMission="updateNewMission"></MissonItem>
                    </div>
                </div>
            </div>
       </div>
       <get-reward-modal @close="showModal = false"/>
    </div>
</template>

<script>
import axios from "axios";
import MissonItem from "./MissonItem.vue"
import {getAchievements} from "../../models/achievement";
import GetRewardModal from '../modal/reward/GetRewardModal.vue';

export default {
    name: "Misson",
    components: {MissonItem,GetRewardModal},
    title: 'Misson',
    props: ['mission_noti'],
    data() {
        return{
            achievements: [],
            showModal: false,
        }
    },

    computed: {
        user_info() {
            return this.$store.state.user_info
        },
        mission_completed_active:{
            get() {
                return this.mission_noti
            },
            set(val) {
                this.$emit('update:mission_noti', val)
            }
        }
    },

    created() {
        this.$store.commit('setMenuActive', 'challenge');

        this.fetch();
    },

    methods: {
        fetch() {
            const that = this;

            axios.all([
                getAchievements(),
            ]).then(axios.spread((response) => {
                that.isLoading = false;

                if (response.data.code != 0) {
                    return;
                }

                that.achievements = response.data.results.achieve || [];
                console.log('cc: ', that.achievements);

                that.$nextTick(function() {
                    var initNanoScroller = function() {
                        if ($('.nano').length > 0) {
                            $(".nano").nanoScroller();
                        }
                    }
                    initNanoScroller();
                })             
            }));
        },
        updateNewMission() {
            this.mission_completed_active = (this.mission_completed_active==0)?0:this.mission_completed_active-1;
        },
    }
}
</script>

<style scoped>
    #misson{
        height: calc(100vh - 250px);
        overflow: auto;
    }
    .content{
        width: 100%;
    }
    .title{
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.02em;
        color: #191715;
        margin-bottom: 8px;
    }
    .desc{
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #191715;
        margin-bottom: 24px;
    }
    .misson-item{
        width: 100%;
        border: 1px solid #CFCFCF;
        border-radius: 24px;
        overflow: hidden;
    }
    ::-webkit-scrollbar {
        display: none;
    }
</style>
