import {get, post} from "../APIGateWay";

export async function getTutorings(params = {}) {
    return await get(params, '/live_class/list')
}
export async function getTutoringsInComming(params = {}) {
    params.hours = params.hours || 5
    return await get(params, '/live_class/class-upcomming')
}
export async function getClassess(params = {}) {
    return await get(params, '/live_class/list')
}

export async function tutoringSubscribe(data) {
    return await post(data, '/live_class/subscribe')
}

export async function joinClass(params = {}) {
    return await get(params, '/live_class/join')
}


// --------------------------------------------------------
export async function getLiveClass(params = {}) {
    return await get(params, '/live_class/list')
}