import {PAGE_LIMIT } from '../constant'
import {get, post, post_1} from "../APIGateWay";

export async function getAllLastest(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/course/get-all-latest-lesson')
}

export async function getLastest(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/course/get-latest-lesson')
}

export async function getLiveClass(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/live_class/class-upcomming')
}

export async function getAllUnit(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, `/course/get-all-unit`)
}

export async function getListUnit(params = {}) {
    params.page = params.page || 1;
    params.limit = params.limit || PAGE_LIMIT;
    return await get(params, '/course/get-unit')
}

export async function getListLesson(params = {}) {
    params.page = params.page || 1;
    return await get(params, `/course/get-list-lesson`)
}

export async function getUnitLessonDetail(subject_id, unit_id, lesson_id) {
    const params = {
        subject_id: subject_id,
        unit_id: unit_id,
        lesson_id: lesson_id,
    }
    return await get(params, '/course/get-item')
}

export async function getUnitLessonItem(item_id, lang = 'vi') {
    const params = {
        item_id: item_id,
        lang: lang
    }
    return await get(params, '/course/get-item-by-id')
}

export async function getListSearch(params = {}) {
    params.page = params.page || 1;
    return await get(params, '/course/search/list')

}

export async function getReportUnit(params = {}) {
    params.page = params.page || 1;
    return await get(params, '/course/get-report-unit')
}

export async function getDiscussions(params = {}) {
    console.log(params instanceof FormData);
    return await get(params, '/course/get-discuss')
}

export async function sendDiscuss(params) {
    return await post(params, '/course/send-discuss')
}
export async function deleteComment(params) {
    return await post(params, '/course/delete-discuss')
}

export async function editComment(params) {
    return await post(params, '/course/edit-discuss')
}

export function getEbook(params) {
    return get(params, '/course/get-ebook', params);
}

export async function getItemInfo(params = {}) {
    return await get(params, '/item-info')
}



// -------------IGS-------------
export async function getAllCourse(params = {}) {
    return await get(params, `/courses`)
}

export async function getModule(params = {}) {
    return await get(params, `/courses/modules`)
}

export async function getUnit1(params = {}) {
    return await get(params, `/courses/modules1`)
}

export async function getLesson(params = {}) {
    return await get(params, `/courses/lessons`)
}

export async function getProgram(params = {}) {
    return await get(params, `/courses/programs`)
}


export async function getListItem(params = {}) {
    return await get(params, '/courses/items')
}

export async function saveCurrentItem(params) {
    return await post(params, '/course/save-current-item')
}

export async function updateItem(params = {}) {
    return await post(params, '/courses/updateitem')
}

export async function searchLearning(params = {}) {
    return await get(params, `/courses/search`)
}


export async function submitItem(params = {}) {
    return await post(params, `/courses/submititem`)
}

export async function updateStatus(params = {}) {
    return await post(params, `/courses/updatestatus`)
}

export async function saveDarfAsm(params = {}) {
    return await post(params, `/courses/savedrafasm`)
}

export async function submitTestModule(params = {}){
    return await post(params, `/courses/submittestmodule`)
}

export async function submitSemeterTest(params = {}){
    return await post(params, `/courses/submitsemetertest`)
}

export async function getLessonPackage(params = {}) {
    return await get(params, '/courses/lessonpackages')
}

// export async function getScores(params = {}) {
//     return await get(params, '/courses/scores')
// }

export async function getScores(params = {}) {
    return await post_1(params, process.env.VUE_APP_CMS_API + '/itoteacher/get_grade_book')
}

export async function NextModule(params = {}){
    return await post(params, `/courses/nextmodule`)
}

export const TestStatus = Object.freeze({
    UNKNOW: "",
    NOT_STARTED: "not-started",
    INPROGRESS: 'processing',
    ON_GOING: "todo",
    SUBMITED: "submitted",
    COMPLETED: "completed",
    NEED_RESUBMIT: "needtoresubmit",
    OVERDUE: "overdue",
    PASS: "pass",
    NOTPASS: "notpass",
});

export const StatusPriority = Object.freeze({
    'completed': 4,
    "todo": 3,
    "not-started": 2,
    "inprogress": 1
});