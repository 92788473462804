<template>
    <popup-modal ref="popup">
        <div class="change-password-modal">
            <a href="javascript:void(0)" class="close-modal">
                <img @click="closeModal" :src="closeImg" alt="Close">
            </a>
            <h2  class="title">{{ title }}</h2>
            <div class="content">
                <p style="margin: 15px 0; color: 000; font-size: 14px">You will be logged out of the system</p>
                <h3 class="desc">Are you sure?</h3>
            </div>
            <div class="bottom">
                <button @click="_confirm()">YES, LOG OUT NOW</button>
                <button @click="closeModal">NO, I WANT TO STAY</button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal.vue'
export default {
    name: "ConfirmLogout",
    components: { PopupModal },
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-1.png',
        title: undefined,
        message: undefined, // Main text content
        search: true,
    }),
    mounted(){

    },
    created(){
       
    },
    methods: {
        _confirm(){
            this.resolvePromise(true);
        },
        show(opts = {}) {
            this.title = "Log Out"
            this.$refs.popup.open()
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },
        closeModal() {
            this.$refs.popup.close();
        }
    },
}
</script>

<style scoped>
    .change-password-modal{
        width: 477px;
        padding: 24px 48px;
        position: relative;
    }
    .title{
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
    }
    .desc{
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
    }
    
    .close-modal{
        position: absolute;
        display: flex;
        justify-self: flex-end;
        justify-content: flex-end;
        /* width: 424px; */
        right: 0;
        cursor: default;
        top: 0;
    }
    .close-modal img{
        cursor: pointer;
    }
    .content .form-group p{
        letter-spacing: 0.02em;
        color: #0D0D0C;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 8px;
    }
    .content .form-group{
        margin-top: 16px;
    }
    .content input{
        height: 80px;
        width: 381px;
        background: #F5F8FB;
        border: 1px solid #AFAFAF;
        border-radius: 10px;
        padding: 0 24px;
        outline: none;
    }
    .content{
        text-align: center;
    }

    .bottom{
        margin-top: 16px;
    }
    .bottom button{
        width: 100%;
        border-radius: 48px;
        height: 52px;
        font-weight: 700;
        font-size: 16px;
        color: #191715;
        text-align: center;
        cursor: pointer;
    }
    .bottom button:nth-child(2){
        margin-top: 20px;
        background: #F8FE5F;
        border: 1px solid #E3E87A;
        box-shadow: 0px 0px 16px #F8FE5F, 4px 4px 0px #E3E87A;
    }

    .bottom button:nth-child(1){
        background: #FFFFFF;
        margin-top: 20px;
        box-shadow: 4px 4px 0px #D2D2D2;
        border: 1px solid #CFCFCF;
    }
</style>