import axios from 'axios'
import {store} from './store'
// import { getToken, removeToken, getAuthType,getUserName, getUserID, getAdminID } from './auth'

// create an axios instance
const service = axios.create({
	baseURL: process.env.BASE_API, // api 的 base_url
	timeout: 3000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		const isCmsPreview = store.getters.isCmsPreview
		if(!isCmsPreview){
			config.headers['Authorization'] = 'Bearer ' + getCookie('_wid')

			if(getCookie('_permtoken'))
				config.headers['Perm-Token'] = getCookie('_permtoken')
		}
		else{
			config.headers['Perm-Token'] = ''
			config.headers['Cms-Token'] = getCookie('_cmstoken')	
		}
		return config
	},
	error => {
		// Do something with request error
		//console.log('use ' + error) // for debug
		Promise.reject('Request Data: ' + error)
	}
)

// response interceptor
service.interceptors.response.use(
	function (response) {
        console.log(response)
		//console.log('code: ' + response.data.code)
		
		// if (response.data.status == "01") {
		// 	removeToken()
		// }
		// if (response.data.status != "00" && response.data.status != "07") {	
		// 	Notify.create({
		// 		message: response.data.message,
		// 		position:'top-right',
		// 		color: 'red'
		// 	})
		// 	return Promise.reject('Reject Response: ' + response.data.status)
		// }

		return response
	},
	function (error) {
		// Do something with response error
		// check for errorHandle config	
	
		// Notify.create({
		// 	message: error.message,
		// 	position:'top-right',
		// 	color: 'red'
		// })
		// if has response show the error
		if (error.response) {
			//console.log("Error Reponse:" + error.response.status) // for debug
			if (error.response.status === 401) {
				// removeToken()
				window.location.href = "/";
			}
		}

		//return Promise.reject(error)
	},	
)
function getCookie(key){
    const cookies = document.cookie
                        .split(';')
                        .map(cookie => cookie.split('='))
                        .reduce((accumulator, [key, value]) => 
                        ({...accumulator, [key.trim()]: decodeURIComponent(value)}),{})
    return cookies[key]
}

export default service
