<template>
    <popup-modal ref="popup">
        <div class="warning-modal">
            <a href="javascript:void(0)" class="close-modal">
                <img @click="closeModal" :src="closeImg" alt="Close">
            </a>
            <h2  class="title">{{ title }}</h2>
            <div class="content">
                <p>
                    The time zone of your account and your current device are <span style="color:#F04821">{{user_info.timezone}}</span> and <span style="color:#F04821">{{deviceTimezone}}</span> . 
                    This might impact on your learning results. You can do 3 methods as below: 
                    <br> 1. Change time zone in SIS
                    <br> 2. Change time zone in your current device
                    <br> 3. Contact your parents or admin team to help you change time zone
                </p>
            </div>
            <div class="bottom">
                <button @click="closeModal">I got it</button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '../modal.vue'
export default {
    name: "Warning",
    components: { PopupModal },
    data: () => ({
        closeImg: '/themes/web/assets/images/icon/icon-closed-1.png',
        title: undefined,
        message: undefined, // Main text content
        search: true,
        user_info: {},
        deviceTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }),
    mounted(){

    },
    created(){
       
    },
    methods: {
        show(opts = {}) {
            this.title = "Warning"
            this.user_info = opts.user_info
            this.$refs.popup.open()
        },
        closeModal() {
            const times = JSON.parse(localStorage.getItem(`warning_timezone_${this.user_info.username}`)) ? JSON.parse(localStorage.getItem(`warning_timezone_${this.user_info.username}`))[this.user_info.username] : 0;
            localStorage.setItem(`warning_timezone_${this.user_info.username}`, JSON.stringify({[this.user_info.username]: times + 1}));
            this.$store.commit('setShowWarning', false);
            this.$refs.popup.close();
        }
    },
}
</script>

<style scoped>
    .warning-modal{
        width: 600px;
        position: relative;
        padding: 24px 48px;
    }
    .title{
        letter-spacing: 0.02em;
        color: #000000;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    .close-modal{
        position: absolute;
        display: flex;
        justify-self: flex-end;
        justify-content: flex-end;
        /* width: 424px; */
        right: 0;
        cursor: default;
        top: 0;
    }
    .close-modal img{
        cursor: pointer;
    }
    .content .form-group p{
        letter-spacing: 0.02em;
        color: #0D0D0C;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 8px;
    }
    .content .form-group{
        margin-top: 16px;
    }
    .content input{
        height: 80px;
        width: 381px;
        background: #F5F8FB;
        border: 1px solid #AFAFAF;
        border-radius: 10px;
        padding: 0 24px;
        outline: none;
    }
    .content p{
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.02em;
        color: #1D1D1F;
        line-height: 25px;
    }
    .bottom{
        display: flex;
        justify-content: center;
        margin-top: 16px;
    }
    .bottom button{
        width: 100%;
        border-radius: 48px;
        height: 52px;
        font-weight: 700;
        font-size: 16px;
        color: #191715;
        text-align: center;
        cursor: pointer;
    }
    .bottom button:nth-child(1){
        margin-top: 20px;
        background: #F8FE5F;
        border: 1px solid #E3E87A;
        width: 300px;
        box-shadow: 0px 0px 16px #F8FE5F, 4px 4px 0px #E3E87A;
    }
</style>