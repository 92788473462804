<template>
    <div id="blank-page">
        <div class="show-destop">
            <div class="content">
                <div v-if="!show_menu" class="left">
                    <div class="btn-close" @click="goHome()" v-if="!isPreviewLessonPackage">
                        <img src="/themes/web/assets/images/icon/icon-rectangle.svg" alt="">
                    </div>
                </div>
                <div v-else style="width: 150px;">
                    <side-bar></side-bar>
                </div>
                <div style="position: relative; width: 100%;">
                    <div v-if="show_menu" class="btn-close pull-left" @click="goHome()">
                        <img src="/themes/web/assets/images/icon/icon-rectangle.svg" alt="">
                    </div>
                    <router-view></router-view>
                </div>
            </div>
           
        </div>
        <div class="show-mobile">
            Download App
        </div>
        <div class="loading" id="loading" v-if="isLoading">
            <img class="spinner" src="/themes/web/assets/images/icon/loadding.png" alt="" />
        </div>
    </div>
</template>

<script>
    import SideBar from "../SideBar";
    export default {
        name: "Empty",

        data() {
            return {
                show_menu: this.$store.getters.isShowMenu
            };
        },
        created() {
            this.$store.dispatch("SET_USER_INFO");
            // this.$store.dispatch("SET_USER_INFO");
            // this.$store.commit("setLoading", true);
        },
        components: {
            SideBar,
        },
        computed: {
            isLoading() {
                return this.$store.getters.isLoading
            },
            user_info() {
                return this.$store.state.user_info;
            },
            isCmsPreview() {
                return this.$store.getters.isCmsPreview;
            },
            isPreviewLessonPackage(){
                return (this.$route.path || "").includes("preview-lesson-package")
            }
        },

        methods: {
            fetch() {

            },
            goHome(){
                if(this.isCmsPreview) return
                this.show_menu = !this.show_menu;

                this.$store.commit('setShowMenu', this.show_menu);

                console.log(this.$store.getters.isShowMenu);

                if(this.show_menu){
                    $('#assignment .right').css({'left' : '130px'});
                    $('#subject >.right').css({'left' : '130px'});
                    $('.webview').css({'left' : '130px'});
                    $('#lesson-package>.right').css({'left' : '130px'});
                    $('.popup-modal').css({'left': '130px'});
                    $('#lesson').css({'left': '130px'});
                }else{
                    $('#assignment .right').css({'left' : '50px'});
                    $('#subject >.right').css({'left' : '50px'});
                    $('.webview').css({'left' : '50px'});
                    $('#lesson-package>.right').css({'left' : '50px'});
                    $('.popup-modal').css({'left': '50px'});
                    $('#lesson').css({'left': '50px'});
                }
            }
        }
    }
</script>

<style scoped>
    #blank-page{
        width: 100%;
    }
    .content{
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
    .left{
        margin-right: 24px;
    }
    .btn-close{
        margin-top: 120px;
        width: 32px;
        height: 124px;
        background: #02A6E7;
        border: 1px solid #2376A8;
        border-radius: 24px 0px 0px 24px;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .btn-close img{
        transform: rotate(180deg);
    }
    .pull-left{
        /* position: absolute; */
        position: fixed;
        z-index: 2;
        transform: rotateY(0);  
        /* left: -32px; */
        left: 98px;
    }
</style>