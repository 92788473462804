<template>
  <div id="lesson">
    <div class="webview">
      <iframe
        allow="microphone *;clipboard-read *; clipboard-write *"
        :src="test.content_url"
        frameborder="0"
        allowfullscreen
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  getListItem,
  saveCurrentItem,
  updateItem,
  submitTestModule,
  submitSemeterTest,
} from "../../models/dashboard";
import { fetchWithHeaders } from "../../APIGateWay";

export default {
  name: "TestDetail",
  components: {},
  title: "TestDetail",

  // props: ['test'],

  data() {
    return {
      current_categories: {},
      list_catagory: [],
      test: JSON.parse(this.$route.query.test),
      type_code: this.$route.query.type,
      class_id: this.$route.query.class_id,
      course_id: this.$route.query.course_id,
      module_id: this.$route.query.module_id,
      unit_id: this.$route.query.unit_id,
      item_id: this.$route.query.test.id,
      lesson_id: this.$route.query.lesson_id,
      learning_item: {},
      lesson_name: "",
      startTime: null,
      elapsedTime: 0,
      intervalId: null,
      action_type: "login",
    };
  },

  created() {
    window.addEventListener("message", this.handleMessage);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
    this.stopTimer();
    this.coutTimeSpent(null, "end");
  },

  mounted() {
    if (this.$store.getters.isShowMenu) {
      $("#subject .right").css({ left: "130px " });
      $("#assignment .right").css({ left: "130px" });
      $(".popup-modal").css({ left: "130px" });
      $("#lesson-package >.right").css({ left: "130px" });
      $("#lesson").css({ left: "130px" });
    } else {
      $("#assignment .right").css({ left: "50px" });
      $("#subject .right").css({ left: "50px" });
      $(".popup-modal").css({ left: "50px" });
      $("#lesson-package >.right").css({ left: "50px" });
      $("#lesson").css({ left: "50px" });
    }
    // this.submitTest();
    this.stopTimer();
    this.startTimer();
    this.coutTimeSpent(null, "login");
    window.addEventListener("beforeunload", (event) =>
      this.coutTimeSpent(event, "end")
    );
  },

  computed: {},

  methods: {
    async coutTimeSpent(event, type) {
      const params = {
        item_id: this.item_id,
        item_type: "end_of_module",
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: 0,
        lesson_package_id: 0,
        class_id: this.class_id,
        time: this.elapsedTime,
        action_type: type,
      };

      console.log(this.test, 123);

      try {
        const data = await fetchWithHeaders("/courses/counttime", params, {
          method: "POST",
        });
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    },
    startTimer() {
      this.startTime = Date.now();
      this.intervalId = setInterval(() => {
        this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
      }, 1000);
    },
    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    async coutTimeSpent1(event, action_type, type) {
      if (action_type == "login") {
        this.elapsedTime = 0;
        this.stopTimer();
        this.startTimer();
      }

      const params = {
        item_id: this.test.id,
        item_type: this.type_code,
        course_id: this.course_id,
        module_id: this.module_id,
        lesson_id: this.lesson_id,
        lesson_package_id: this.lesson_package_id,
        class_id: this.class_id,
        time: this.elapsedTime,
        action_type: action_type,
        type: type,
      };

      try {
        const data = await fetchWithHeaders("/courses/counttime", params, {
          method: "POST",
        });
        this.elapsedTime = 0;
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    },
    async handleMessage(event) {
      if (event.data.type === "update_item_info") {
        this.submitTest();
        console.log("submit");
      }
      if (event.data.type === "start") {
        await this.coutTimeSpent1(null, "login", "learning");
        console.log("start");
      }
      if (event.data.type === "submit") {
        await this.coutTimeSpent1(null, "end", "submit");
        console.log("start");
      }
      if (event.data.type === "save_draf") {
        await this.coutTimeSpent1(null, "save", "save");
        console.log("save");
      }
      if (event.data.type == "save_draf") {
        console.log("savedraf");
        this.saveDarfAsm();
      }
      if (event.data.type == "close_iframe") {
        const path = "/subject/" + this.course_id + "/" + this.class_id;
        this.$router.push(path);
      }
    },

    submitTest() {
      if (this.type_code == "end_of_semester") {
        const params = {
          course_id: this.course_id,
          module_id: this.module_id,
          class_id: this.class_id,
        };
        axios
          .all([submitSemeterTest(params)])
          .then(axios.spread((response) => {}));
      } else if (this.type_code == "end_of_module") {
        const params = {
          course_id: this.course_id,
          module_id: this.module_id,
          class_id: this.class_id,
        };
        axios
          .all([submitTestModule(params)])
          .then(axios.spread((response) => {}));
      }
    },

    getItemInfo() {
      axios
        .all([
          updateItem({
            id: this.learning_item.id,
            item_type: this.learning_item.type_code,
          }),
        ])
        .then(
          axios.spread((response) => {
            if (response.data.code != 0) {
              return;
            }
          })
        )
        .catch((error) => {
          console.log("sai");
        });
    },

    saveCurrentItem(itemId) {
      formData.append("item_id", itemId);
      saveCurrentItem(formData)
        .then((response) => {
          that.$store.commit("setLoading", false);
          if (response.data.code != 0) {
            return;
          }
        })
        .catch((response) => {
          that.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style scoped>
#lesson {
  margin-right: 24px;
  overflow: auto;
  height: 100%;
  position: fixed;
  right: 0;
}
::-webkit-scrollbar {
  display: none;
}
.header {
  height: 32px;
  margin-bottom: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.webview {
  box-shadow: 4px 0px 24px rgba(0, 0, 0, 0.25);
  background: #f8fafc;
  border-radius: 24px;
  /* height: 738px; */
  height: calc(100% - 48px);
  overflow: hidden;
  margin-top: 24px;
}
.igs-btn {
  height: 32px;
  width: 47px;
  background: #ffffff;
}
.igs-btn.btn-menu {
  width: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.path {
  letter-spacing: 0.02em;
  color: #00afff;
  font-weight: 500;
  font-size: 16px;
}
.path-1 {
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 16px;
  color: #f8fe5f;
}
</style>
