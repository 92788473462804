<template>
    <div id="misson-item">
        <div class="mission-item can-claim" v-if="achievement.status == 1">
            <div class="mission-item-left">
                <img src="/themes/web/assets/images/icon/diamond-misson-1.svg" alt="">
                <p>{{ $t(achievement.gem) }}</p>
            </div>
            <div class="mission-item-middle">
                <p class="name">{{ $t(achievement.name) }} ({{$t('level')}} {{ achievement.level }})</p>
                <div class="progress">
                    <div class="progress-load"
                        v-bind:style="{ width: achievement.process/achievement.max_process*100 + '%' }"></div>
                    <span class="precent">{{ achievement.process }}/{{ achievement.max_process }}</span>
                </div>
                <div class="desc">{{ $t(achievement.desc) }}</div>
            </div>
            <div class="mission-item-right">
                <button class="igs-btn" @click="claim(achievement)">
                    Collect
                </button>
            </div>
        </div>    


        <!-- <div class="mission-item claimed" v-if="achievement.status == 2">
            <div class="mission-item-left">
                <img src="/themes/web/assets/images/icon/diamond-misson-1.svg" alt="">
                <p>{{ $t(achievement.gem) }}</p>
            </div>
            <div class="mission-item-middle">
                <p class="name">{{ $t(achievement.name) }} ({{$t('level')}} {{ achievement.level }})</p>
                <div class="progress">
                    <div class="progress-load"
                        v-bind:style="{ width: achievement.process/achievement.max_process*100 + '%' }"></div>
                    <span class="precent">{{ achievement.process }}/{{ achievement.max_process }}</span>
                </div>
                <div class="desc">{{ $t(achievement.desc) }}</div>
            </div>
            <div class="mission-item-right">
                <button class="igs-btn">
                    Collect
                </button>
            </div>
        </div>     -->

        <div class="mission-item no-claim" v-if="achievement.status == 0">
            <div class="mission-item-left">
                <img src="/themes/web/assets/images/icon/diamond-misson-1.svg" alt="">
                <p>{{ $t(achievement.gem) }}</p>
            </div>
            <div class="mission-item-middle">
                <p class="name">{{ $t(achievement.name) }} ({{$t('level')}} {{ achievement.level }})</p>
                <div class="progress">
                    <div class="progress-load"
                        v-bind:style="{ width: (achievement.process/achievement.max_process + 0.04)*104 + '%' }"></div>
                    <span class="precent">{{ achievement.process }}/{{ achievement.max_process }}</span>
                </div>
                <div class="desc">{{ $t(achievement.desc) }}</div>
            </div>
            <div class="mission-item-right">
                <button class="igs-btn">
                    Collect
                </button>
            </div>
        </div> 
        
        <!-- <div class="modal modal-flex" id="tutoringHelpModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="formErrorModal" aria-hidden="true">
            <div class="modal-dialog" style="width: 207px; max-width: 207px;">
                <div class="modal-content">
                    <div class="modal-body">
                        <h4 class="title">{{$t('colour_explanation')}}</h4>
                        <div class="tutoring-help-modal">
                            <p><span class="c1"></span>{{$t('class_start')}}</p>
                            <p><span class="c2"></span>{{$t('class_upcomming')}}</p>
                            <p><span class="c5"></span>{{$t('class_finish')}}</p>
                        </div>
                    </div>
                    <p type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></p>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from 'vuex'
import {claimAchievement} from "../../models/achievement";
import {soundClick, soundGetReward, soundClaimKey, soundClaimGem} from '../../utils/utils';

export default {
    name: "MissonItem",
    components: {},
    title: 'MissonItem',

    props: ['achievement'],

    data() {
        return{
            
        }
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return {
                gem: 0
            }
        }),
        ShowModal: {
            get() {
                return this.showModal
            },
            set(val) {
                this.$emit('update:showModal', val)
            }
        }
    },

    created() {
        
    },

    methods: {
        claim(achievement) {
            $('#getRewardedModal').modal('show');
            const that = this;
            that.getSoundClaimGem();
            claimAchievement({
                id: achievement.id
            }).then((response) => {
                if (response.data.code != 0) {
                    return;
                }

                that.achievement.status = response.data.results.status || '';

                const new_gem = response.data.results.update_gem || 0;
                const gem = response.data.results.gem_reward || 0;

                that.user_info.gem = new_gem;

                $('#congratulation_gem').text(gem);
                $('#getRewardedModal').modal('show');
                
                this.$emit('updateNewMission')
                //location.reload();
            }).catch(({response}) => {
                console.error(response);
            });
        },
        getSoundClick() {
            soundClick();
        },

        getSoundGetReward() {
            soundGetReward();
        },

        getSoundClaimKey() {
            soundClaimKey();
        },

        getSoundClaimGem() {
            soundClaimGem();
        }
    }
}
</script>

<style scoped>
    #misson-item{
        overflow: hidden;
    }
    .content{
        width: 100%;
    }
    .mission-item{
        display: flex;
        height: 127px;
        width: 100%;
        border-bottom: 1px solid #DDDDDD;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 31px;
        overflow: hidden;
    }
    .mission-item-left{
        margin-right: 50px;
    }

    .mission-item-left p{
        font-weight: 700;
        font-size: 24px;
        color: #191715;
        letter-spacing: 0.02em;
    }
    .mission-item-middle .name{
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #191715;
        margin-bottom: 8px;
    }
    .mission-item-middle .desc{
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #777777;
        margin-top: 9px;
    }

    .mission-item-middle .progress {
        position: relative;
        opacity: 1;
        background: #8549BA;
        border-radius: 16px;
        height: 32px;
        width: 100%;
        padding: 2px;
    }
    .mission-item-middle .progress-load {
        background: #FC7A1B;
        border-radius: 48px;
        transition: none 0s ease 0s;
        height: 100%;
    }
    .mission-item.can-claim .progress-load{
        background: #F8FE5F;
    }
    .mission-item.can-claim .precent{
        color: #A560EB;
    }
    .mission-item-middle{
        flex: 1;
    }
    .precent{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        letter-spacing: 0.02em;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 20px;
    }
    .mission-item-right{
        margin-left: 40px;
    }
    .igs-btn{
        border: 1px solid #191715;
    }
    .mission-item.claimed .igs-btn, .mission-item.no-claim .igs-btn{
        border: 1px solid #DDDDDD;
        color: #CFCFCF;
        background: #F8FAFC;
        box-shadow: 4px 4px 0px #DDDDDD;
        border-radius: 48px;
    }
    
</style>
