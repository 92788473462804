<template>
    <div id="daily-challenge" ref="challenge">
        <div class="loading" id="loading" v-if="isLoading">
            <img class="spinner" src="/themes/web/assets/images/icon/loadding.png" alt="" />
        </div>
        <div>
            <h4 class="title">Statistics</h4>
            <div class="progress-challenge">
                <div>
                    <div>
                        <p>Complete 10 Daily Challenges</p>
                        <div class="progress">
                            <div class="progress-load"
                                v-bind:style="{ width:  mission_completed /10*100 + '%' }"></div>
                                <span class="precent">{{ mission_completed }}/10</span>
                        </div> 
                    </div>
                    <button class="igs-btn continue" @click="scrollDown()">Continue</button>
                </div>

                <div class="right">
                    <img style="margin-bottom: 20px;" src="/themes/web/assets/images/icon/key-challenges-1.svg" alt="">
                    <div>
                        <p>{{ key_collect }}</p>
                        <p>Total Keys</p>
                        <p class="time-challenge">{{ time_remain_txt }}</p>
                    </div>
                </div>
            </div>


            <div class="daily-challenge-list">
                <div class="daily-challenge-items">
                    <div v-for="(challenge, index) in challenges" v-bind:key="index">
                        <ChallengeItem :isLoading.sync="isLoading" :challenge="challenge" :key_collect="key_collect" @updateNewChallenge="updateNewChallenge"></ChallengeItem>
                    </div>
                </div>
            </div>

            <div id="missons" class="missons" ref="missons">
                <h4>Daily Challenges</h4>
                <p>Daily challenges are open everyday. Each challenge completed will help you collect the key. 
                <br> Use key to open chests and get rewards!</p>

                <div class="list-missons">
                    <div class="daily-challenge-misson-items">
                        <div v-for="(missions, index) in missions" v-bind:key="index">
                            <DailyChallengeMissionItem :isLoading.sync="isLoading" :mission="missions" @updateNewMission="updateNewMission" @updateNewChallenge="updateNewChallenge"></DailyChallengeMissionItem>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="modal fade modal-flex" id="dailyBonusModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="dailyBonusModal" aria-hidden="true">
                <div class="modal-dialog" style="width: 539px; max-width: 539px;">
                    <div class="modal-content" style="padding-bottom: 15px;">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">{{$t('daily_bonus')}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="daily-bonus">
                                <div class="daily-bonus-gem">
                                    <div class="text-1">{{ $t(user_info.dailybonus_dayname) }}</div>
                                    <div class="gem">
                                        <i class="lisa-icon-gem-large"></i>
                                    </div>
                                    <div class="text-2">+{{ user_info.dailybonus_gemreward }}</div>
                                </div>
                                <div class="daily-bonus-content">
                                    {{$t('daily_bonus_msg')}}
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button  @click="claim" type="button" class="btn btn-main-border m-auto" style="padding: 8px 15px;">{{$t('claim')}}</button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from 'vuex'
import {getDailyChallenge} from '../../models/reward'
import ChallengeItem from "./ChallengeItem.vue";
import DailyChallengeMissionItem from "./DailyChallengeMissionItem.vue";
import {pushQueries, soundClick, convertSecondToText} from "../../utils/utils";
// import GetRewardModal from "../modal/reward/GetRewardModal";
// import GetRewardLockModal from "../modal/reward/GetRewardLockModal";

export default {
    name: "Challenge",
    components: {ChallengeItem, DailyChallengeMissionItem},
    title: 'Challenge',
    props: ['challenge_noti'],
    data() {
        return{
            challenges: [],
            missions: [],
            mission_completed: 0,
            key_collect: 0,
            isLoading: true,
            time_remain: 0,
            time_remain_txt: '00:00:00',
        }
    },

    computed: {
        ...mapGetters([
            'user_info'
        ], () => {
            if (this.user_info) {
                return this.user_info
            }

            return {
                gem: 0
            }
        }),
        mission_completed_active:{
            get() {
                return this.challenge_noti
            },
            set(val) {
                this.$emit('update:challenge_noti', val)
            }
        }
    },

    watch: {
        time_remain: {
            handler(value) {

                if (value > 0) {
                    setTimeout(() => {
                        this.time_remain--;

                        this.time_remain_txt = convertSecondToText(this.time_remain) || '';
                    }, 1000);
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }
    },

    created() {
        this.$store.commit('setMenuActive', 'challenge');
        this.fetch();
    },

    methods: {
        fetch() {
            const that = this;

            axios.all([
                getDailyChallenge(),
            ]).then(axios.spread((response) => {
                that.isLoading = false;

                if (response.data.code != 0) {
                    return;
                }

                that.challenges = response.data.results.challenge || [];
                that.missions = response.data.results.mission || [];
                that.key_collect = response.data.results.key_collect || 0;
                that.time_remain = response.data.results.time_remain || 0;
                var mission_active = 0;
                response.data.results.mission.forEach(x=>{ 
                    if(x.status!=0){ that.mission_completed+=1 } 
                    if(x.status==1){ mission_active+=1 }
                })
                // that.mission_completed_active = mission_active;

                // this.$nextTick(function () {
                //     that.initSwiper();
                // });

            }));
        },

        claim() {
            const that = this;

            that.getSoundClick();

            that.$store.commit("setLoading", true);

            claimDailyBonus().then((response) => {
                that.$store.commit("setLoading", false);

                //if (response.data.code != 0) {
                //  return;
                //}

                //that.bonus.status = response.data.results.status || '';

                const gem_reward = response.data.results.gem_reward || 0;
                const new_gem = response.data.results.update_gem || 0;

                that.user_info.gem = new_gem;

                const gem_txt = gem_reward >= 0 ? '+' + gem_reward : '-' + gem_reward;

                //that.getSoundClaimTreasure();

                $('#congratulation_gem').text(gem_txt);
                $('#dailyBonusModal').modal("hide");
                $('#getRewardedModal').modal();
                this.$emit('updateFirstLogin', 0)
            }).catch((response) => {
                that.$store.commit("setLoading", false);

                console.error(response);
            });
        },

        initSwiper() {
            return new Swiper(".swiper-container", {
                slidesPerView: "auto",
                freeMode: true,
                slideToClickedSlide: true,
                spaceBetween: 0,
                scrollbar: {
                    el: ".swiper-scrollbar",
                    draggable: true
                },
                mousewheel: true
            });
        },

        updateNewChallenge(challenges, key_collect) {
            this.challenges = challenges;
            this.key_collect = key_collect;
            this.mission_completed_active = (this.mission_completed_active==0)?0:this.mission_completed_active-1;
            // console.log(this.mission_completed_active)
        },

        updateNewMission(new_key_collect, new_mission_id, new_mission_status) {
            //this.missions = new_mission;
            this.key_collect = new_key_collect;
            this.missions.forEach(element => {
                if (element.id == new_mission_id) {
                    element.status = new_mission_status;
                }
            });
        },
        scrollDown(){
            document.getElementById("daily-challenge").scrollTop = 460;
        },
    }
}
</script>

<style scoped>
    #daily-challenge{
        height: calc(100vh - 250px);
        /* overflow: auto; */
        overflow-y: scroll;
    }
    .content{
        width: 100%;
    }
    .igs-btn.continue{
        height: 56px;
        font-size: 24px;
    }
    .progress-challenge{
      display: flex;
      justify-content: space-between;
      gap: 24px;
      height: 104px;
      margin-bottom: 24px;
    }
    .progress-challenge>div:nth-child(1){
        background: radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%);
        border: 4px solid #8549BA;
        border-radius: 24px;
        width: 699px;
        height: 104px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        padding: 16px 24px;
    }
    .progress-challenge>div>div{
        flex: 1;
    }
    .progress-challenge>div>div>p{
        letter-spacing: 0.02em;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
    }
    .progress-challenge .progress {
        position: relative;
        opacity: 1;
        background: #8549BA;
        border-radius: 16px;
        height: 32px;
        width: 100%;
        padding: 2px;
        margin: 14px 0 12px 0;
    }
    .progress-challenge .progress-load {
        background: #FC7A1B;
        border-radius: 16px;
        transition: none 0s ease 0s;
        height: 100%;
    }
    .progress-challenge .precent{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }
    .progress-challenge .right{
        background-color: red;
        width: 269px;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #CFCFCF;
        border-radius: 24px;
        padding: 16px 20px;
        display: flex;
        justify-content: space-between;
        gap: 28px;
        align-items: center;
    }
    .title{
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.02em;
        color: #191715;
        margin-bottom: 16px;
    }
    .progress-challenge .right > div p:nth-child(1){
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.02em;
        color: #191715;
    }
    .progress-challenge .right > div p:nth-child(2){
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 16px;
        color: #403D3D;
        margin-bottom: 8px;
    }
    .progress-challenge .right > div p:nth-child(3){
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.02em;
        color: #FC7A1B;
    }
    .daily-challenge-items{
        display: flex;
        gap: 12px;
        height: 240px;
        overflow: auto;
    }
    .daily-challenge-list{
        margin-bottom: 48px;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    .missons h4{
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.02em;
        color: #191715;
        margin-bottom: 8px;
    }
    .missons > p{
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #191715;
        margin-bottom: 16px;
    }
    .list-missons{
        width: 100%;
        border: 1px solid #CFCFCF;
        border-radius: 24px;
        padding: 24px 16px;
    }
    .daily-challenge-misson-items{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 12px;
    }
    .daily-challenge-misson-items>div{
        /* width: 20%; */
    }
</style>
